import React from "react";
import "./order-process.css";
import SEO from "../../seo/SEO";

const OrderProcess = () => {
  return (
    <div className="order-process-root margin-horizontal-order-process">
      <SEO
        title="Order Process - Right Sight Research"
        description="Understand the step-by-step order process at Right Sight Research."
        canonicalUrl="https://www.rightsightresearch.com/order-process"
      />

      <div className="order-process-title">
        Get Reports That Matter: Effortless Ordering Process with Right Sight
        Market Research Pvt. Ltd
      </div>
      <div className="order-process-p-container">
        <div className="order-process-p">
          Market information may help you remain ahead of the competition and
          grow your business, so place your order with us immediately.
        </div>
        <div className="order-process-p">
          When it comes to getting informative market research, we at
          RightSightResearch.com know how important a streamlined ordering
          experience is. Because of this, we have made placing an order as easy
          as possible.
        </div>
        <div className="order-process-p">
          Your business strategy and your company's success can benefit
          significantly from the wealth of market information just a few clicks
          away.
          <br />
        </div>
        <div className="order-process-p">
          Our user-friendly layout makes it easy to look through our extensive
          collection of studies and choose the ones that meet your needs. After
          selecting your preferred reports, you can complete your order due to
          our safe and secure checkout process.
        </div>
        <div className="order-process-p">
          After placing a purchase, you'll have immediate access to the reports,
          allowing you to get started analyzing the data that matters most to
          your business. If you're looking for reliable data on which to base
          important decisions, we're here to provide it.
          <br />
        </div>
        <div className="order-process-p">
          If you have any questions or concerns about placing an order, our
          professional customer support is here to help. We believe in giving
          you a personalized experience and making sure your needs are met at
          every step.
          <br />
        </div>
      </div>
      <div className="notes-text">Notes: </div>
      <ul className="notes-list">
        <li>
          If you'd instead not order online but still want to share with us your
          payment information, you may do so by clicking the "print" option to
          the right of the report title on the report details page. All of the
          data from your report, including the fax/email order form, will be
          printed out using this feature.
        </li>
        <li>
          Take a look at our detailed company profile if you'd like to include
          RightSightResearch.com as a potential supplier in future dealings with
          your company, such as when preparing your Purchase Order or making a
          payment.
        </li>
        <li>
          Please get in touch with us if you'd like to order reports offline or
          if you'd want a formal estimate on company paper.
        </li>
        <li>
          Select the market research study you'd like to buy, then choose the
          appropriate user license from single user to worldwide user licenses
          before clicking the "Add to Cart" button.
        </li>
        <li>
          You may learn more about the available report licenses by clicking the
          link just below the "Add to Cart" button.
        </li>
      </ul>
      <div className="order-process-heading">
        Please refer to the steps outlined below when you complete your order.
        The 'checkout bar' at the top of each page shows this process.
      </div>
      <div className="order-process-step-heading">Step 1. Your Cart</div>
      <ol className="steps-list">
        <li>
          Cart information is readily available to view. You can change the
          number of reports in your cart or remove reports completely from this
          page.
        </li>
        <li>
          Next, you'll see your shopping cart reports. Before checking out, you
          may add or remove reports.
        </li>
      </ol>
      <div className="order-process-step-heading">Step 2. Checkout</div>
      <ol className="steps-list">
        <li>Login with your details.</li>
        <li>
          You can only move forward if you are an existing customer and have
          entered your login information.
        </li>
        <li>
          New users have to create an account before continuing with our app.
        </li>
      </ol>
      <div className="order-process-step-heading">Step 3. Your Information</div>
      <ol className="steps-list">
        <li>Enter company information after entering login credentials.</li>
        <li>
          You can skip this section if you have already provided your business
          information.
        </li>
      </ol>
      <div className="order-process-step-heading">Step 4. Wrap-up</div>
      <ol className="steps-list">
        <li>
          We accept a variety of payment methods, all of which are listed on the
          payment page.
        </li>
        <ul>
          <li>Credit card payments are accepted.</li>
          <li>Visa, MasterCard, and American Express are accepted.</li>
          <li>
            You can choose 'payment by credit card via Phone or Fax' if you want
            to handle credit card data over the phone.
          </li>
          <li>PayPal payments.</li>
          <li>Pay with a check.</li>
        </ul>
        <li>Your report will be given after check collection.</li>
        <li>
          Select your payment option and click "Place Order" to complete your
          order.
        </li>
        <li>
          You will receive an email confirmation of your order during this
          process.
        </li>
        <li>Reports will be delivered within hours on business days.</li>
        <li> If delivery takes more than a few hours, we'll let you know.</li>
      </ol>
    </div>
  );
};
export default OrderProcess;
