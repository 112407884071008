import React, { useState, useContext } from "react";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import { Blog } from "../../../types/@types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./blogs.css";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import { BlogContext } from "../../../contexts/BlogContext";
import AdminBlogsHeader from "./AdminBlogsHeader";

const Blogs = () => {
  const { blogs, isLoading, deleteBlog, updateBlog } = useContext(BlogContext);
  const [selectedBlog, setSelectedBlog] = useState<Blog | null>(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleBlogDelete = async () => {
    if (selectedBlog) {
      if (!selectedBlog._id) return toast.error("Please refresh the page");
      try {
        await deleteBlog(selectedBlog._id);
        setTitle("");
        setContent("");
        setMetaDescription("")
        setSelectedBlog(null);
        setShowDeleteModal(false);
        toast.success("Blog deleted successfully!");
      } catch (error) {
        toast.error("An error occurred while deleting the blog.");
      }
    }
  };

  const handleBlogUpdate = async () => {
    if (selectedBlog) {
      if (!selectedBlog._id) return toast.error("Please refresh the page");
      try {
        await updateBlog(selectedBlog._id, {
          title,
          content,
          metaDescription
        });
        setTitle("");
        setContent("");
        setMetaDescription("")
        setSelectedBlog(null);
        setShowEditModal(false);
        toast.success("Blog updated successfully!");
      } catch (error) {
        toast.error("An error occurred while updating the blog.");
      }
    }
  };

  const handleBlogDiscard = () => {
    setTitle("");
    setContent("");
    setMetaDescription("");
    setSelectedBlog(null);
    setShowEditModal(false);
  };

  const handleEditClick = (event: any, blog: Blog) => {
    event.stopPropagation();
    setSelectedBlog(blog);
    setTitle(blog.title);
    setContent(blog.content);
    setMetaDescription(blog.metaDescription)
    setShowEditModal(true);
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (event: any, blog: Blog) => {
    event.stopPropagation();
    setSelectedBlog(blog);
    setShowDeleteModal(true);
    setShowEditModal(false);
  };
  return (
    <div className="admin-dashboard-blogs-root">
      <Loader isLoading={isLoading} />
      <AdminBlogsHeader modules={modules} formats={formats} />

      <div className="blogs-list">
        <ListGroup>
          {blogs.map((blog: Blog) => (
            <ListGroup.Item key={blog._id}>
              <div className="blog-content">
                <p className="fs-6">{blog.title}</p>
                <div className="list-buttons">
                  <Button
                    onClick={(event) => handleEditClick(event, blog)}
                    variant="outline-primary"
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={(event) => handleDeleteClick(event, blog)}
                    variant="outline-danger"
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>

      <Modal show={showEditModal} onHide={handleBlogDiscard} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Content</Form.Label>
              <ReactQuill
                value={content}
                onChange={setContent}
                modules={modules}
                formats={formats}
                className="text-field"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Meta Description</Form.Label>
              <Form.Control
                type="text"
                value={metaDescription}
                onChange={(e) => setMetaDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleBlogDiscard}>
            Close
          </Button>
          <Button variant="primary" onClick={handleBlogUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: 18 }}>
            Are you sure you want to delete this blog?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleBlogDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Blogs;
