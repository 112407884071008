import React from "react";

const CheckoutPaymentOptions = () => {
  return (
    <div className="panel panel-default">
      <div
        className="panel-heading"
        style={{
          background: "#2f6e89",
          textAlign: "center",
          borderRadius: 0,
        }}
      >
        <h4 style={{ color: "#fff", padding: 8, fontSize: 20 }}>Payment Options</h4>
      </div>
      <div
        className="d-flex flex-column justify-content-between m-3"
        style={{ height: "360px" }}
      >
        <div className="d-flex flex-column gap-4 mt-4">
          <div>
            <input type="radio" className="mx-2" />
            <img
              alt="paypal"
              src={require("../../assets/paypal-logo.png")}
              style={{
                width: 120,
              }}
            />
          </div>
          <div>
            <input type="radio" className="mx-2" />
            <img
              alt="amex.png"
              src={require("../../assets/razorpay-logo.png")}
              style={{
                width: 120,
              }}
            />
          </div>
        </div>
        <button type="submit" className="btn btn-success btn-block pl-3">
          Place Order
        </button>
      </div>
    </div>
  );
};

export default CheckoutPaymentOptions;
