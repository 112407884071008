import React, { useState, useEffect } from "react";
import { Button, ListGroup, Modal, Form } from "react-bootstrap";
import "./reports.css";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import {
  deleteEntireFile,
  deleteReports,
  getUploadDates,
  getReportsByUploadDate,
  getReports,
  deleteMultipleReports,
} from "../../../api/reports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Pagination";
import { Report } from "../../../types/@types";
import { formatDateTime } from "../../../utils/formatDateTime";
import AdminReportsHeader from "./AdminReportsHeader";

const Reports = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [showDeleteSelectedReportsModal, setShowDeleteSelectedReportsModal] =
    useState(false);
  const [selectedReport, setSelectedReport] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadDates, setUploadDates] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [segment, setSegment] = useState<"all" | "grouped">("all");
  const [selectedReportIds, setSelectedReportIds] = useState<number[]>([]);
  const [selectAllSelected, setSelectAllSelected] = useState(false);

  const handleEntireFileDelete = async (report: Report[]) => {
    setIsLoading(true);
    try {
      await deleteEntireFile(report[0].fileId);
      const response = await getReports(currentPage);
      setReports(response.data.data);
      fetchUploadDates();
      setShowDeleteFileModal(false);
      toast.success("File deleted successfully!");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while deleting the file.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedReportsDelete = async () => {
    setIsLoading(true);
    try {
      await deleteMultipleReports(selectedReportIds);
      if (segment === "all") getAllFiles();
      else if (segment === "grouped") getGroupedFiles();
      setSelectedReportIds([]);
      setShowDeleteSelectedReportsModal(false);
      setSelectAllSelected(false);
      toast.success("Selected reports deleted successfully");
    } catch (error) {
      toast.error("An error occured while deleting the selected reports");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAll = () => {
    setSelectAllSelected(!selectAllSelected);
    if (!selectAllSelected)
      setSelectedReportIds(reports.map((report) => report._id));
    else setSelectedReportIds([]);
  };

  const fetchUploadDates = async () => {
    setIsLoading(true);
    try {
      const response = await getUploadDates();
      setUploadDates(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileDelete = async () => {
    setIsLoading(true);
    try {
      await deleteReports(selectedReport._id);
      const response = await getReports(currentPage);
      setReports(response.data.data);
      toast.success("Report deleted successfully!");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while deleting the report.");
    } finally {
      setIsLoading(false);
    }
    setShowDeleteReportModal(false);
  };

  const handleDeleteClick = (event: any, file: any) => {
    event.stopPropagation();
    setSelectedReport(file);
    setShowDeleteReportModal(true);
  };

  const handleClose = () => {
    setSelectedReport(null);
    setShowDeleteReportModal(false);
    setShowDeleteFileModal(false);
    setShowDeleteSelectedReportsModal(false);
  };

  const getGroupedFiles = async () => {
    if (selectedDate) {
      setIsLoading(true);
      try {
        const response = await getReportsByUploadDate(
          selectedDate,
          currentPage
        );
        setReports(response.data.data);
        setTotalCount(response.data.totalItems);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getAllFiles = async () => {
    setIsLoading(true);
    try {
      const response = await getReports(currentPage);
      setReports(response.data.data);
      setTotalCount(response.data.totalItems);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUploadDates();
  }, []);

  useEffect(() => {
    setSelectedDate(uploadDates[0]);
  }, [uploadDates]);

  useEffect(() => {
    setCurrentPage(1);
  }, [segment]);

  useEffect(() => {
    if (segment === "all") getAllFiles();
    else if (segment === "grouped") getGroupedFiles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectedDate, segment]);

  return (
    <div className="admin-dashboard-reports-root">
      <Loader isLoading={isLoading} />
      <AdminReportsHeader
        segment={segment}
        setSegment={setSegment}
        getAllFiles={getAllFiles}
        getGroupedFiles={getGroupedFiles}
        fetchUploadDates={fetchUploadDates}
      />

      {segment === "grouped" && (
        <div className="upload-dates-container">
          {uploadDates.map((date) => (
            <div key={date.toString()}>
              <Button
                variant="success"
                onClick={() => setSelectedDate(date)}
                className="upload-date-btn"
              >
                {formatDateTime(date)}
              </Button>
              <FontAwesomeIcon
                icon={faCircleXmark}
                onClick={(e) => setShowDeleteFileModal(true)}
                className="cross-icon"
              />
            </div>
          ))}
        </div>
      )}

      <div
        className="reports-list"
        style={{ overflowX: "auto", maxWidth: "100%" }}
      >
        <ListGroup style={{ minWidth: "1024px" }}>
          <ListGroup.Item
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", columnGap: 4 }}>
              <Form.Check
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectAllSelected}
              />
              <span>Select All</span>
            </div>
            <Button
              variant="danger"
              onClick={() => setShowDeleteSelectedReportsModal(true)}
            >
              Delete Selected
            </Button>
          </ListGroup.Item>
          {reports.map((item: Report) => (
            <ListGroup.Item key={item._id}>
              <div className="report-content">
                <Form.Check
                  type="checkbox"
                  onChange={() => {
                    if (selectedReportIds.includes(item._id))
                      setSelectedReportIds(
                        selectedReportIds.filter((id) => id !== item._id)
                      );
                    else setSelectedReportIds((prev) => [...prev, item._id]);
                  }}
                  checked={selectedReportIds.includes(item._id)}
                />
                <div className="fs-6">{item.title}</div>
                <Button
                  onClick={(event) => handleDeleteClick(event, item)}
                  variant="outline-danger"
                >
                  Delete
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>

      <Pagination
        totalItems={totalCount}
        currentPage={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
          setSelectAllSelected(false);
          setSelectedReportIds([]);
        }}
      />

      <Modal show={showDeleteFileModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete All Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: 18 }}>
            Are you sure you want to delete all the reports from this file?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteFileModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleEntireFileDelete(reports)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteSelectedReportsModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Selected Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: 18 }}>
            Are you sure you want to delete all the selected reports?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteSelectedReportsModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleSelectedReportsDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteReportModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: 18 }}>
            {selectedReport ? selectedReport.title : ""}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleFileDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Reports;
