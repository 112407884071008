import React from "react";
import { formatDate } from "../../../utils/formatDate";
import { formatUrlString } from "../../../utils/formatUrlString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendarDays,
  faDownload,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import CategoryIcon from "../../CategoryIcon/CategoryIcon";
import { formatString } from "../../../utils/formatString";

import { Link } from "react-router-dom";

interface MainReportDetailHeaderProps {
  id: string;
  title: string;
  publishDate: Date;
  publisher: string;
  pages: number;
  category: string;
  onDownloadClick: () => void;
}

const MainReportDetailHeader = (props: MainReportDetailHeaderProps) => {
  const {
    title,
    publishDate,
    publisher,
    pages,
    category,
    id,
    onDownloadClick,
  } = props;

  if (!title || !publisher || !category) return <></>;

  return (
    <div className="main-report-detail-header-root">
      <div>
        <div className="report-title-wrapper">
          <div
            className="report-title"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <Link
                to={`/reports/${formatUrlString(title)}/${id}`}
                className="title-link-report"
              >
                {title}
              </Link>
            </div>
          </div>
          <div className="download-btn-container">
            <button className="download-btn" onClick={onDownloadClick}>
              <FontAwesomeIcon icon={faDownload} color="#02A971" size="lg" />
              <div className="download-btn-txt">Download</div>
            </button>
          </div>
        </div>
        <div className="d-flex gap-4 mt-2 flex-wrap">
          <div className="d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              icon={faCalendarDays}
              color="#028D5E"
              style={{
                borderRadius: 40,
                background: "#E6F6F1",
                padding: 8,
              }}
              width={16}
              height={16}
            />
            <div className="report-detail-info">{formatDate(publishDate)}</div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              icon={faUser}
              color="#2BADF8"
              style={{
                borderRadius: 40,
                background: "#EBF2FF",
                padding: 8,
              }}
              width={16}
              height={16}
            />
            <Link
              to={`/publisher/${formatString(publisher)}`}
              className="report-detail-info"
            >
              {publisher}
            </Link>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              icon={faBook}
              color="#E0482A"
              style={{
                borderRadius: 40,
                background: "#FCEFED",
                padding: 8,
              }}
              width={16}
              height={16}
            />
            <div className="report-detail-info">{pages} Pages</div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <CategoryIcon
              categoryName={category}
              style={{
                color: "#04525F",
                borderRadius: 40,
                background: "#F3EFFE",
                padding: 8,
                width: 16,
                height: 16,
              }}
            />
            <Link
              to={`/category/${formatString(category)}`}
              className="report-detail-info"
            >
              {category}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainReportDetailHeader;
