import React from "react";
import "./terms-and-conditions.css";
import PageHeader from "../../components/PageHeader";
import SEO from "../../seo/SEO";

const HandDComponentFirst = [
  {
    subHeading: "Use Permitted; Some Restrictions Apply",
    description:
      "Keep all copyright and other notices on the Material, and you may view the Material on your web browser for your personal use. One copy of the content may be printed for your personal use. Nothing in this Agreement provides you a license to reproduce, distribute, or publicly display any of the content in any way. Third, Party Content may come with its collection of notifications and guidelines, which you must follow. For more information, see the Terms of Service.",
  },
];

const HandDComponentSecond = [
  {
    subHeading: "Cybersecurity Strategies",
    description:
      "Directly or indirectly, you are not permitted to: (a) attempt or actually disrupt, impair or interfere with, change or modify Our Service or any Material; (b) get or try to collect any information of others, including usernames and passwords account or other information; nor are you permitted to allow others to do so.",
  },
];
const HandDComponentThird = [
  {
    subHeading: "No Suggestions",
    description:
      "All content provided by Our Service is intended to be used for educational purposes only.",
  },
];

const HandDComponentForth = [
  {
    subHeading: "External Website Links",
    description:
      "The connections throughout Our Service will eventually lead you away from Our Service. We cannot guarantee the safety of the information you find at any linked site. The content of any linked website or any other page not operated by us is not our responsibility. These links are provided for your convenience and do not signify that we endorse the content on any site they lead.",
  },
];
const HandDComponentFifth = [
  {
    subHeading: "Privacy",
    description:
      "The privacy Notice that applies to your use of RightSightResearch.com can be viewed at RightSightResearch.com/privacy-policy. Please read it thoroughly to familiarize yourself with our policies.",
  },
];
const HandDComponentSixth = [
  {
    subHeading: "Communications Technology",
    description:
      "You use electronic communication when you visit RightSightResearch.com or email us. You agree that we may contact you via technology. You can expect to hear from us via email or notifications posted on RightSightResearch.com. It is mutually understood that any contracts, notices, reports, and other communications that we deliver to you electronically will satisfy any need for such communications to be in writing.",
  },
];

const HandDComponentSeventh = [
  {
    subHeading: "Liability Restrictions",
    description:
      'When using Our Service, you accept full responsibility for any actions taken while browsing or using Our Service. Your exclusive and unique solution concerning any disagreement with the Information or Our Service or these Terms of Use is to quit using the Material and Our Service. There will be no compensation from our end.We do not guarantee the accuracy, clarity, currentness, or additional features of any Material on Our Service. Our Service is provided "as is," and we will not be responsible for any losses suffered as a result of it, whether they be direct or indirect, and no matter whether they were caused by our mistakes or external factors.Neither we nor any Third Party Content providers will be held accountable for any loss or damage that results from your use of, reliance on, or inability to access Our Service or the Material.  Our service is at your risk. We offer our service "as is" and "as available." We provide no guarantees or warranties of any kind, either express or implied, and our service is for your own personal use alone. we make no implied or expressed warranties, such as any warranty of merchantability or suitability for a specific purpose. If you choose to leave our Service and visit a third-party website, we are not responsible for the content or privacy practices of that website and will not be a party to any transactions between you and that website.',
  },
];

const HandDComponentEighth = [
  {
    subHeading: "Legal Foundations",
    description:
      "This Agreement and your use of Our Service are governed by the rules of India State of Maharashtra (India). All applicable laws, rules, duties, and restrictions are your responsibility to follow. For any matter, cause of action, or dispute originating out of or relating to this Agreement, you and our company both agree that the courts in Maharashtra (India) shall have exclusive authority.You also accept and agree that the State of Maharashtra has the right to make use of jurisdiction over you. There will be no cancellation of any rights by any party failure or delay in executing them. This Agreement shall remain in full force and effect without any determination that any part of this Agreement is illegal, prohibited, or enforceable.All of the content on our site is protected by our copyright unless otherwise specified. Except as specifically allowed in this Agreement, you may not use any of the content in any way.",
  },
];
const HandDComponentNinth = [
  {
    subHeading: "Trademark",
    description:
      "All company, product, and service names, and related graphics, names, and logos used in Our Service or the information are the registered or unregistered trademarks, service marks, or trade names of the owners of those marks and are protected by national, state, and local copyright and copyright regulations.",
  },
];

const HandDComponentTen = [
  {
    subHeading: "A few more agreements",
    description:
      'If you or your company have signed a direct contract with RightSightResearch.com ("Agreement") and the terms of that Agreement conflict with the terms of this Terms and Conditions Document, the terms of your signed Agreement shall control.',
  },
];

const HandDComponentEleventh = [
  {
    subHeading: "Policy on Refunds  ",
    description:
      "We understand that you may have concerns about the refund policy for our product. Please note that due to the digital nature of the information in the report, we cannot offer refunds under any circumstances.To ensure a satisfying experience, we strongly encourage you to carefully read all the details about the report before purchasing. We believe that the content of the information will be valuable and beneficial to you.If you have any questions or concerns regarding the scope or applicability of the study, we encourage you to reach out to our team of Research Specialists. They are available to provide further information and address any queries.Thank you for considering our product, and we are here to assist you throughout the process.",
  },
];
const HandDComponent = (props: {
  data: { subHeading: string; description: string }[];
}) => {
  const { data } = props;
  return (
    <div className="subheading-d-terms-and-condition">
      {data.map(({ subHeading, description }) => {
        return (
          <div className="subheading-d-terms-and-condition-root">
            <h4 className="terms-and-condition-heading">{subHeading}</h4>
            <div className="terms-and-condition-description">{description}</div>
          </div>
        );
      })}
    </div>
  );
};

const TermsAndConditions = () => {
  return (
    <div className="terms-and-condition-container margin-horizontal-tnc-page">
      <SEO
        title="Terms & Conditions - Right Sight Research"
        description="Review the terms and conditions of using Right Sight Research’s services."
        canonicalUrl="https://www.rightsightresearch.com/terms-and-conditions"
      />

      <PageHeader title="Terms of use" />
      <div className="p-terms-and-condition-div">
        <p className="p-terms-and-condition">
          Right Sight Market Research Pvt. Ltd. manages the website
          RightSightResearch.com
        </p>

        <p className="p-terms-and-condition">
          Authorized users - "you" or "your", who have completed the
          registration process, are granted access to specific online
          information and services on various websites operated by
          RightSightResearch.com or its subsidiaries. These websites are
          collectively and individually referred to as "we," "our," or "us."
          This access is provided under the terms outlined in these Terms of
          Service, also known as the "Agreement," governing your use of our
          service.
        </p>
        <p className="p-terms-and-condition">
          All of the data, information, content, updates, programs, video,
          audio, and other services, conversations, transmissions, and other
          physical and digital things that are created known through our Service
          are collectively referred to as "Material." If you use Our Service,
          you accept this Agreement and agree to be bound by its terms.
        </p>
        <p className="p-terms-and-condition">
          Unless we have another formal agreement covering your use of some or
          all of Our Services, this is our whole Agreement. This Agreement
          governs your use of Our Service in every aspect, except as may be
          provided in a separate written agreement between you and us.
        </p>
        <p className="p-terms-and-condition">
          If we don't tell you differently or if an additional written agreement
          governs your use of a new product or Service, this Agreement will
          apply to it.
        </p>
        <p className="p-terms-and-condition">
          Use of third-party stuff, software, and products (collectively,
          "Third-Party Content") may be subject to extra "passthrough" terms and
          conditions. The relevant Third Party Content will be provided together
          with any different terms and restrictions.
        </p>
        <p className="p-terms-and-condition">
          In the event that you are not eligible or are unable to comply with
          such terms and conditions due to situations beyond your control, you
          must notify RightSightResearch.com of such exclusion within between 24
          and 72 hours of purchase, especially in the case of Instant Online
          Delivery content or getting (in the case of other content).
        </p>
        <p className="p-terms-and-condition">
          At that moment, we can figure out the best way forward. If you do not
          respond to the call within the specified period, you will be regarded
          to have accepted the new terms and conditions and be in compliance
          with them.
        </p>
        <p className="p-terms-and-condition">
          In addition, when you access certain areas or features of Our Service,
          you may be subject to additional or alternative conditions, "Special
          Terms," which will be made clear to you then. Regarding particular
          places or functions, the Additional Terms are also a part of this
          Agreement.
        </p>
        <p className="p-terms-and-condition">
          Concerning such sites or services, the Special provisions shall
          control to the extent that they are incompatible with the rules of
          this Agreement. You can review the most up-to-date version of this
          Agreement on our website anytime.
        </p>
        <p className="p-terms-and-condition">
          Your continued use of Our Service beyond the date this Agreement is
          updated will be considered your acknowledgment of the revised terms.
          Any modifications to this Agreement must be written and signed by both
          you and us to be effective.
        </p>
      </div>

      <div className="terms-and-condition-root">
        <HandDComponent data={HandDComponentFirst} />
        <HandDComponent data={HandDComponentSecond} />
        <HandDComponent data={HandDComponentThird} />
        <HandDComponent data={HandDComponentForth} />
        <HandDComponent data={HandDComponentFifth} />
        <HandDComponent data={HandDComponentSixth} />
        <HandDComponent data={HandDComponentSeventh} />
        <HandDComponent data={HandDComponentEighth} />
        <HandDComponent data={HandDComponentNinth} />
        <HandDComponent data={HandDComponentTen} />
        <HandDComponent data={HandDComponentEleventh} />
      </div>
    </div>
  );
};

export default TermsAndConditions;
