import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { reformatString } from "../../utils/reformatString";
import { Link, useLocation } from "react-router-dom";
import { Report } from "../../types/@types";
import { getReport } from "../../api/reports";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { formatUrlString } from "../../utils/formatUrlString";
import "./breadcrumb-wrapper.css";

interface BreadCrumbWrapperProps {
  path: string;
}

const BreadCrumbWrapper = (props: BreadCrumbWrapperProps) => {
  const { path } = props;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const report_id = query.get("report_id");

  const [reportData, setReportData] = useState<Report>({} as Report);
  const [isLoading, setIsLoading] = useState(false);

  let items: { title: string; url: string }[] = [];

  if (path === "/") items.push({ title: "Home", url: "/" });
  else {
    const pathComponents = path.split("/");

    pathComponents.forEach((pathComponent) => {
      if (pathComponent === "category")
        items.push({ title: "Category", url: "/categories" });
      else if (pathComponent === "reports")
        items.push({ title: "Reports", url: "/all-reports" });
      else if (pathComponent === "checkout")
        items.push(
          { title: "Reports", url: "/all-reports" },
          {
            title: reformatString(formatUrlString(reportData.title)),
            url: `/reports/${formatUrlString(reportData.title)}/${
              reportData.customId
            }`,
          },
          { title: "Checkout", url: "" }
        );
      else if (pathComponent === "all-reports")
        items.push({ title: "Reports", url: "/all-reports" });
      else if (pathComponent === "") items.push({ title: "Home", url: "/" });
      else if (pathComponent === "search")
        items.push({ title: "Search", url: "/all-reports" });
      else if (!Number.isNaN(parseInt(pathComponent))) return;
      else if (pathComponent.includes("rsr-")) return;
      else
        items.push({
          title: reformatString(pathComponent),
          url: `/${pathComponent}`,
        });
    });
  }

  useEffect(() => {
    if (!report_id) return;

    (async () => {
      try {
        const report = await getReport(report_id);
        setReportData(report.data);
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while fetching the report.");
      } finally {
        setIsLoading(false);
      }
    })();
  }, [report_id]);

  return (
    <Breadcrumb>
      <Loader isLoading={isLoading} />

      {items.map((item, index) => {
        if (index === items.length - 1) {
          return (
            <Breadcrumb.Item active key={Math.random()}>
              {item.title}
            </Breadcrumb.Item>
          );
        } else {
          return (
            <Breadcrumb.Item key={Math.random()}>
              <Link to={item.url}>
                
              {item.title}
              </Link>
            </Breadcrumb.Item>
          );
        }
      })}
    </Breadcrumb>
  );
};

export default BreadCrumbWrapper;
