import React, { useState, useEffect } from "react";
import AdminMessagesHeader from "./AdminMessagesHeader";
import { Button, Card } from "react-bootstrap";
import "./messages.css";
import { BecomePublisherMessage, Message } from "../../../types/@types";
import { deleteMessage, getMessagesByType } from "../../../api/messages";
import Loader from "../../Loader";
import { getCountryName } from "../../../utils/getCountryName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

type MessageTypes = Message | BecomePublisherMessage;

type segments =
  | "sample-report"
  | "enquiry"
  | "check-discount"
  | "become-publisher";

interface MessageComponentProps {
  message: Message;
  getMessages: () => Promise<void>;
}

interface BecomePublisherMessageComponentProps {
  message: BecomePublisherMessage;
  getMessages: () => Promise<void>;
}

const MessageComponent = (props: MessageComponentProps) => {
  const { message, getMessages } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteMessage(message._id);
      getMessages();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="message-root">
      <Loader isLoading={isLoading} />
      <div className="message-box-1">
        <h4>Details</h4>
        <div className="scrollable-table">
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Name:</strong>
                </td>
                <td>
                  {message.firstName} {message.lastName}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Email:</strong>
                </td>
                <td>{message.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Job Title:</strong>
                </td>
                <td>{message.jobTitle}</td>
              </tr>
              <tr>
                <td>
                  <strong>Company:</strong>
                </td>
                <td>{message.company}</td>
              </tr>
              <tr>
                <td>
                  <strong>Phone No.:</strong>
                </td>
                <td>{message.phoneNumber}</td>
              </tr>
              <tr>
                <td>
                  <strong>Country:</strong>
                </td>
                <td>{getCountryName(message.country)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="message-box-2">
        <h4>Message</h4>
        {message.question}
      </div>
      <div className="message-box-3">
        <h4>Actions</h4>
        <div style={{ display: "flex", columnGap: 32, marginTop: 32 }}>
          <Link to={message.linkToReport} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFile} className="message-box-3-icon" />
          </Link>
          <Button
            variant="link"
            onClick={handleDelete}
            className="message-box-3-button"
          >
            <FontAwesomeIcon icon={faTrash} className="message-box-3-icon" />
          </Button>
        </div>
      </div>
    </Card>
  );
};

const BecomePublisherMessagesComponent = (props: BecomePublisherMessageComponentProps) => {
  const { message, getMessages } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteMessage(message._id);
      getMessages();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="message-root">
      <Loader isLoading={isLoading} />
      <div className="message-box-1">
        <h4>Details</h4>
        <div className="scrollable-table">
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Title:</strong>
                </td>
                <td>
                  {message.title}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Name:</strong>
                </td>
                <td>{message.name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Email:</strong>
                </td>
                <td>{message.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Organizaiton:</strong>
                </td>
                <td>{message.organization}</td>
              </tr>
              <tr>
                <td>
                  <strong>Job Title:</strong>
                </td>
                <td>{message.jobTitle}</td>
              </tr>
              <tr>
                <td>
                  <strong>Address:</strong>
                </td>
                <td>{message.address}</td>
              </tr>
              <tr>
                <td>
                  <strong>City:</strong>
                </td>
                <td>{message.city}</td>
              </tr>
              <tr>
                <td>
                  <strong>State/Province/Region:</strong>
                </td>
                <td>{message.state}</td>
              </tr>
              <tr>
                <td>
                  <strong>Zip/Postal Code:</strong>
                </td>
                <td>{message.zipCode}</td>
              </tr>
              <tr>
                <td>
                  <strong>Country:</strong>
                </td>
                <td>{getCountryName(message.country)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Phone Number:</strong>
                </td>
                <td>{message.phoneNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="message-box-3">
        <h4>Actions</h4>
        <div style={{ display: "flex", columnGap: 32, marginTop: 32 }}>
          <Link to={message.linkToReport} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFile} className="message-box-3-icon" />
          </Link>
          <Button
            variant="link"
            onClick={handleDelete}
            className="message-box-3-button"
          >
            <FontAwesomeIcon icon={faTrash} className="message-box-3-icon" />
          </Button>
        </div>
      </div>
    </Card>
  );
};

const Messages = () => {
  const [segment, setSegment] = useState<segments>("sample-report");
  const [messages, setMessages] = useState<MessageTypes[]>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getMessages = async () => {
    setIsLoading(true);
    try {
      const res = await getMessagesByType(segment);
      setError("");
      setMessages(res.data.reverse());
    } catch (error) {
      setMessages([]);
      setError("No messages here...");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment]);

  return (
    <div className="admin-dashboard-messages-root">
      <Loader isLoading={isLoading} />
      <AdminMessagesHeader />
      <div className="messages-segmentation-buttons">
        <Button
          onClick={() => setSegment("sample-report")}
          variant={segment === "sample-report" ? "dark" : "outline-dark"}
        >
          Sample Report
        </Button>
        <Button
          onClick={() => setSegment("enquiry")}
          variant={segment === "enquiry" ? "dark" : "outline-dark"}
        >
          Enquiry before buy
        </Button>
        <Button
          onClick={() => setSegment("check-discount")}
          variant={segment === "check-discount" ? "dark" : "outline-dark"}
        >
          Check Discount
        </Button>
        <Button
          onClick={() => setSegment("become-publisher")}
          variant={segment === "become-publisher" ? "dark" : "outline-dark"}
        >
          Become Publisher
        </Button>
      </div>
      <div style={{ margin: "32px 0" }}>
        {messages &&
          segment !== "become-publisher" &&
          messages.map((message) => (
            <MessageComponent
              key={message._id}
              message={message as Message}
              getMessages={getMessages}
            />
          ))}
        {messages &&
          segment === "become-publisher" &&
          messages.map((message) => (
            <BecomePublisherMessagesComponent
              key={message._id}
              message={message as BecomePublisherMessage}
              getMessages={getMessages}
            />
          ))}
        {error.length > 0 && <h5>{error}</h5>}
      </div>
    </div>
  );
};

export default Messages;
