import React from "react";
import PageHeader from "../../../components/PageHeader";
import "./syndicated-reports.css";
import SEO from "../../../seo/SEO";

const SyndicatedReport = () => {
  return (
    <div className="syndicated-research-root horizontal-margin-syndicated-research-root">
      <SEO
        title="Syndicated Market Research Reports | Right Sight Research"
        description="Unlock market insights with our syndicated reports. Expert analysis, trends, and opportunities for strategic decision-making. Stay ahead in your industry!"
        canonicalUrl="https://www.rightsightresearch.com/syndicated-reports"
      />

      <PageHeader title="Syndicated Research Reports" />
      <div>
        <p className="syndicated-research-p">
          We work with hundreds of our market research publisher partners around
          the world to provide a comprehensive list of ready market research
          reports on various industry verticals.
        </p>
        <p className="syndicated-research-p">
          Some of the key advantages of our syndicated market research reports.{" "}
        </p>
        <ul>
          <li className="syndicated-research-p">Cost effective</li>
          <li className="syndicated-research-p">Readily available data</li>
          <li className="syndicated-research-p">
            {" "}
            Helps a business in identifying market-specific trends.
          </li>
          <li className="syndicated-research-p">
            Sample size is larger than custom market research
          </li>
          <li className="syndicated-research-p">
            Can be used for business plan, research thesis or investment Pitch
            deck
          </li>
        </ul>
        <p className="syndicated-research-p">
          If you are looking for any industry specific research report then
          please drop us an email at info@rightsightresearch.com and we will be
          happy to help you get what you are looking for.{" "}
        </p>
      </div>
    </div>
  );
};

export default SyndicatedReport;
