import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Loader from "../../Loader";
import { ReportsContext } from "../../../contexts/ReportsContext";

interface AdminReportsHeaderProps {
  segment: "all" | "grouped";
  setSegment: React.Dispatch<React.SetStateAction<"all" | "grouped">>;
  getAllFiles: () => Promise<void>;
  getGroupedFiles: () => Promise<void>;
  fetchUploadDates: () => Promise<void>;
}

const AdminReportsHeader = (props: AdminReportsHeaderProps) => {
  const { isLoading, createReport } = useContext(ReportsContext);
  const { segment, setSegment, getAllFiles, getGroupedFiles, fetchUploadDates } = props;
  const [file, setFile] = useState<File | null>(null);
  const [publisher, setPublisher] = useState("");
  const [showAddReportsModal, setShowAddReportsModal] = useState(false);

  const handleFileCancel = () => {
    setFile(null);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
  });

  const handleFileSubmit = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("publisher", publisher);
      try {
        await createReport(formData);
        setFile(null);
        toast.success("File submitted successfully!");
        setShowAddReportsModal(false);
        if (segment === "all") getAllFiles();
        else if (segment === "grouped") getGroupedFiles();
        fetchUploadDates();
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while submitting the file.");
      } finally {
        setPublisher("");
      }
    }
  };

  const handleClose = () => {
    setShowAddReportsModal(false);
  };

  return (
    <div>
      <Loader isLoading={isLoading} />
      <h2>
        Reports<span className="underline"></span>
      </h2>
      <div className="admin-report-header-buttons">
        <div className="add-reports-btn-container">
          <Button
            onClick={() => setShowAddReportsModal(true)}
            className="add-reports-btn"
            variant="dark"
          >
            <FontAwesomeIcon icon={faPlus} color="#fff" /> Add Reports
          </Button>
        </div>
        <div className="segmentation-buttons">
          <Button
            onClick={() => setSegment("all")}
            variant={segment === "all" ? "dark" : "outline-dark"}
          >
            All
          </Button>
          <Button
            onClick={() => setSegment("grouped")}
            variant={segment === "grouped" ? "dark" : "outline-dark"}
          >
            Grouped
          </Button>
        </div>
      </div>

      <Modal show={showAddReportsModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="add-reports-modal-header">
          <Modal.Title>Add Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body className="report-input-container">
          <div className="publisher-input-container">
            <label className="h5">Publisher</label>
            <input
              type="text"
              value={publisher}
              onChange={(e) => setPublisher(e.target.value)}
              className="publisher-input"
              required
            />
          </div>
          {file ? (
            <div>
              <div className="file-action-title">File: {file.name}</div>
              <div className="file-action-buttons">
                <Button variant="primary" onClick={handleFileSubmit}>
                  Submit
                </Button>
                <Button variant="danger" onClick={handleFileCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop .xlsx files here, or click to select files</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminReportsHeader;
