import React, { useContext } from "react";
import "./categories-header.css";
import Loader from "../Loader";
import { CategoryContext } from "../../contexts/CategoryContext";
import { formatString } from "../../utils/formatString";
import useIsMobile from "../../hooks/useIsMobile";
import { Link } from "react-router-dom";

const CategoriesHeader = () => {
  const isMobile = useIsMobile();
  const memoizedFormatString = React.useCallback(formatString, []);

  const { categories, isLoading } = useContext(CategoryContext);
  return (
    <div className="main-nav-container">
      <Loader isLoading={isLoading} />
      <nav id="main-nav-content">
        {!isMobile && (
          <div className="center">
            <div className="main-nav-lvl-0 no-before-line">
              <Link to={`/categories`} style={{ fontWeight: "600" }}>
                All Categories
              </Link>
            </div>
            {categories.map((category) => (
              <div
                className="main-nav-lvl-0 active-sub"
                key={category.category}
              >
                <Link
                  to={`/category/${memoizedFormatString(category.category)}`}
                >
                  {category.category}
                </Link>
              </div>
            ))}
          </div>
        )}
      </nav>
    </div>
  );
};

const MemoizedCategoriesHeader = React.memo(CategoriesHeader);
export default MemoizedCategoriesHeader;
