import React from "react";

type TabTypes = "description" | "table" | "sample" | "enquiry" | "discount";

interface MainReportDetailNavigationProps {
  setTab: React.Dispatch<React.SetStateAction<TabTypes>>;
  tab: TabTypes;
  id: string;
}

const MainReportDetailNavigation = (props: MainReportDetailNavigationProps) => {
  const { setTab, tab, id } = props;
  const tabMapping: Record<TabTypes, { text: string; link?: string }> = {
    description: { text: "Report Description" },
    table: { text: "Table Of Content" },
    sample: { text: "Sample Report", link: `/sample-report/${id}` },
    enquiry: { text: "Enquiry before buy", link: `/enquiry/${id}` },
    discount: { text: "Check Discount", link: `/check-discount/${id}` },
  };
  return (
    <div style={{ marginTop: 20, zIndex: 1 }}>
      <ul className="nav tablist nav-tablist">
        {Object.entries(tabMapping).map(([key, { text, link }]) => (
          <li key={key} className={tab === key ? "active" : ""}>
            <button
              onClick={(event) => {
                event.preventDefault();
                setTab(key as TabTypes);
              }}
            >
              {text}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MainReportDetailNavigation;
