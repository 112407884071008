import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Blog as BlogType } from "../../types/@types";
import { BlogContext } from "../../contexts/BlogContext";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import "./blog.css";
import { formatDate } from "../../utils/formatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import SEO from "../../seo/SEO";

const Blog = () => {
  const { id } = useParams<{ id: string }>();
  const { getBlog, isLoading } = useContext(BlogContext);
  const [blog, setBlog] = useState<BlogType | null>(null);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const blog = await getBlog(id);
        setBlog(blog.data);
      } catch (error) {
        console.error(error);
        toast.error("No such blog exists");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  if (!blog) return <></>;
  return (
    <div className="blog-root">
      <SEO
        title="Blog - Right Sight Research"
        description="Read our latest blog post for in-depth market insights."
        canonicalUrl={`https://www.rightsightresearch.com/blog/${id}`}
      />

      <Loader isLoading={isLoading} />
      <div className="blog-container">
        <div className="blog-header">
          <div className="publish-date">{formatDate(blog.publishedDate)}</div>
          <div className="blog-title">
            <h1>
              <strong>{blog.title}</strong>
            </h1>
          </div>
        </div>
        <hr />
        <div
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
        <div className="blog-meta-description">
          <FontAwesomeIcon
            icon={faTag}
            style={{ color: "#c2c2c2", marginRight: 8 }}
          />
          <em>{blog.metaDescription}</em>
        </div>
        <hr />
        <div
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
      </div>
    </div>
  );
};

export default Blog;
