import React, { useContext, useState, useEffect } from "react";
import { Blog } from "../../types/@types";
import { BlogContext } from "../../contexts/BlogContext";
import Loader from "../../components/Loader";
import { Card, Pagination } from "react-bootstrap";
import { extractFirstImg } from "../../utils/extractFirstImage";
import "./bloglist.css";
import { formatDate } from "../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import { BLOG_LIST_MAX_BLOGS } from "../../utils/constants";
import PageHeader from "../../components/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import SEO from "../../seo/SEO";

const BlogList = () => {
  const navigate = useNavigate();
  const { blogs, isLoading } = useContext(BlogContext);

  const [filteredBlogs, setFilteredBlogs] = useState<Blog[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const filtered = blogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredBlogs(filtered);
    setCurrentPage(1);
  }, [searchQuery, blogs]);

  const indexOfLastBlog = currentPage * BLOG_LIST_MAX_BLOGS;
  const indexOfFirstBlog = indexOfLastBlog - BLOG_LIST_MAX_BLOGS;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredBlogs.length / BLOG_LIST_MAX_BLOGS);
    i++
  ) {
    pageNumbers.push(i);
  }

  return (
    <>
      <SEO
        title="Blogs - Right Sight Research"
        description="Stay updated with the latest industry insights and trends with our blogs."
        canonicalUrl="https://www.rightsightresearch.com/blogs"
      />

      <Loader isLoading={isLoading} />
      <div className="w-75 m-auto ">
        <div className="d-flex justify-content-between align-items-center mt-3)">
          <PageHeader title="Blog" />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>
        {currentBlogs.map((blog) => {
          const imgUrl = extractFirstImg(blog.content);
          return (
            <Card className="border border-secondary my-3 " key={blog._id}>
              {imgUrl && (
                <Card.Img src={imgUrl} height={100} className="card-image" />
              )}
              <div className="card-publishdate">
                {formatDate(blog.publishedDate)}
              </div>
              <Card.Body>
                <Card.Text className="h3 mb-2">
                  <strong>{blog.title}</strong>
                </Card.Text>
                <hr />
                <Card.Text className="card-desc mb-3">
                  <span dangerouslySetInnerHTML={{ __html: blog.content }} />
                </Card.Text>
                <Card.Text className="card-meta mb-2">
                  <FontAwesomeIcon
                    icon={faTag}
                    style={{ color: "#c2c2c2", marginRight: 8 }}
                  />
                  <em>{blog.metaDescription}</em>
                </Card.Text>
                <div className="d-flex justify-content-center">
                  <button
                    className="card-button"
                    onClick={() => navigate(`/blog/${blog._id}`)}
                  >
                    Read More
                  </button>
                </div>
              </Card.Body>
            </Card>
          );
        })}
        {pageNumbers.length > 1 && (
          <Pagination>
            {pageNumbers.map((num) => (
              <Pagination.Item
                key={num}
                active={num === currentPage}
                onClick={() => paginate(num)}
              >
                {num}
              </Pagination.Item>
            ))}
          </Pagination>
        )}
      </div>
    </>
  );
};

export default BlogList;
