import React from "react";
import "./contact-us.css";
import { countries } from "../../utils/countries";
import PageHeader from "../../components/PageHeader";
import { Button } from "react-bootstrap";
import SEO from "../../seo/SEO";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <SEO
        title="Contact Us - Right Sight Research"
        description="Get in touch with the team at Right Sight Research."
        canonicalUrl="https://www.rightsightresearch.com/contact-us"
      />

      <PageHeader title="Contact Us" />
      <div className="row">
        <div className="col-sm-6" style={{ paddingBottom: 20 }}>
          <div className="contact-us-leave-a-message">
            <div className="leave-a-msg-root">
              <h4 style={{ backgroundColor: "#055160", color: "#FFF" }}>
                Leave a message
              </h4>
            </div>

            <div style={{ padding: 40 }}>
              <form className="contact-us-form-h" id="contact_us_form">
                <div className="row">
                  <div className="contact-us-leave-a-msg-form-grp">
                    <label className="contact-us-form-label">
                      Full Name:<span>*</span>
                    </label>
                    <br />
                    <input
                      className="contact-us-form-handle"
                      id="contact_us_name"
                      name="contact_us[name]"
                      placeholder="Your name:"
                      type="text"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="contact-us-leave-a-msg-form-grp">
                    <label className="contact-us-form-label">
                      Email:<span>*</span>
                    </label>
                    <input
                      className="contact-us-form-handle"
                      id="contact_us_email"
                      name="contact_us[email]"
                      placeholder="Your email:"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="contact-us-leave-a-msg-form-grp">
                    <label className="contact-us-form-label">
                      Company Name:<span>*</span>
                    </label>
                    <input
                      className="contact-us-form-handle"
                      id="contact_us_company"
                      name="contact_us[company]"
                      placeholder="Your Company:"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="contact-us-leave-a-msg-form-grp">
                    <label className="contact-us-form-label">
                      Job Title:<span>*</span>
                    </label>
                    <input
                      className="contact-us-form-handle"
                      id="contact_us_job_title"
                      name="contact_us[job_title]"
                      placeholder="Your Job Title:"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="contact-us-leave-a-msg-form-grp">
                    <label className="contact-us-form-label">Phone No:</label>
                    <input
                      className="contact-us-form-handle"
                      id="contact_us_phone_no"
                      name="contact_us[phone_no]"
                      placeholder="Your Phone No.:"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="contact-us-leave-a-msg-form-grp">
                    <label className="contact-us-form-label">
                      Country:<span>*</span>
                    </label>
                    <select
                      className="contact-us-form-handle"
                      id="contact_us_country_id"
                      name="contact_us[country_id]"
                    >
                      <option value="">--select country--</option>
                      {countries.map((country) => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="contact-us-form-grp">
                    <label className="contact-us-form-label">
                      Message:<span>*</span>
                    </label>
                    <textarea
                      className="contact-us-form-handle validate[required]"
                      id="contact_us_message"
                      name="contact_us[message]"
                      placeholder="Your message:"
                      style={{ height: 100 }}
                    ></textarea>
                  </div>
                </div>

                <div className="row-sub-btn">
                  <div className="contact-us-form-grp ">
                    <Button
                      type="submit"
                      className="contact-us-submit-btn"
                      id="send_contact"
                      variant="success"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div
            className="contact-us-leave-a-message"
            style={{ marginBottom: 40 }}
          >
            <div className="Contact-information">
              <h4 className="Contact-information-h4">Contact Informations</h4>
            </div>

            <br />
            <div className="contact-address" style={{ overflowY: "auto" }}>
              <div className="col-sm-12  contact-address">
                <ul className="list-unstyled ">
                  <li
                    style={{
                      borderBottom: "1px solid #055160",
                      marginBottom: 10,
                    }}
                  >
                    <strong style={{ color: "#055160", fontSize: "18px" }}>
                      {" "}
                      Head Office:
                    </strong>
                  </li>
                  <li className="contact-us-address-li">
                    Located in: Nexus Seawoods{" "}
                  </li>
                  <li className="contact-us-address-li">
                    Tower 1, Seawoods Grand Central, Railway Station, 10th
                    Floor, Sector 40, Seawoods, Navi Mumbai, Maharashtra 400706
                  </li>
                  <li className="contact-us-address-li"> Maharashtra India</li>

                  <li className="contact-us-address-li">
                    Ph: +1-415-449-5492 (US)
                  </li>
                  <li className="contact-us-address-li">
                    Ph: +91-859-114-1509 (Ind)
                  </li>
                  <li className="contact-us-address-li">
                    <Link
                      to="mailto:info@rightsightresearch.com"
                      className="contact-us-address-li"
                    >
                      info@rightsightresearch.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="contact-us-leave-a-message">
            <div>
              <h4 className="Contact-information-h4">Contact Person</h4>
            </div>

            <div className="contact-person-container">
              <div
                className="row contact-person-container"
                style={{ padding: 15 }}
              >
                <div className="user-profile contact-person-root">
                  <div className="col-sm-12">
                    <h5 className="contact-person-h5">Bhumita Singh </h5>
                    <h6 className="contact-person-h5">
                      Manager - Partner Relations &amp; International Marketing
                    </h6>
                    <p className="contact-us-address-li">
                      Partner Relations & Marketing Manager
                    </p>

                    <Link
                      to="mailto:info@rightsightresearch.com"
                      className="contact-us-address-li"
                    >
                      info@rightsightresearch.com
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
