import React, { useState } from "react";
import "./faqs.css";
import PageHeader from "../../components/PageHeader";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import SEO from "../../seo/SEO";

const firstQandA = [
  {
    question: "Why use RightSightResearch.com?",
    answer:
      "Experts at RightSightResearch.com are professionals in creating innovative strategies to increase output, income, and customer satisfaction. Our business intelligence consultants create a multi-channel marketing strategy focusing on costs and unique products and services based on a continuous market evaluation to help our clients achieve business excellence. The business world continues to change and rarely seems as it did in the past. As a result, RightSightResearch.com develop robust business strategies that can be easily modified in considering new information.",
  },
  {
    question: "How do I make use of RightSightResearch.com?",
    answer:
      "You'll find an intelligent keyword search area in the top right corner of each page on RightSightResearch.com. Alternatively, you may explore research by market or category. When you locate a report of interest, you may click on the title to get further product information, like an introductory table of contents, a free sample of the full report, and a list of the companies included in the analysis.",
  },
  {
    question: "How can I contact customer service?",
    answer:
      "Please email us at    to get in touch with our team of research specialists and customer support experts. Service to customers is available from 9:00 am to 8:00 pm—Standard Time (IST) in India on Weekdays (Monday to Friday).",
  },
];

const secondQandA = [
  {
    question: "When I go to RightSightResearch.com, what do I do?",
    answer:
      "To get the most out of the site, newcomers should sign up. After signing up or logging in, you can search for market research papers that meet your company's needs. Read the report's abstract, table of contents, sample, and list of companies mentioned to learn more.",
  },
  {
    question: "How can I add an item to my Shopping Cart?",
    answer:
      "Select the appropriate publication type from the drop-down menu and add it to your Cart by clicking the 'Add To Cart' button.",
  },
  {
    question: "How can I go to my Cart?",
    answer:
      "The 'Shopping Cart' feature on the right-hand sidebar will show you a complete list of all publications in the Cart. Look at what you've ordered, and then proceed to the checkout.",
  },
];

const thirdQandA = [
  {
    question: "Is registration required on RightSightResearch.com?",
    answer:
      "No. To see our research database, registration on our site is not required. If you want to buy a publication of your choice from us, then you will need to register. If you are not already registered with us, a user account will be created for you when you purchase. If you want to make the most of this site, you should sign up. Once logged in, you can view your account information by clicking the Account icon in the top right corner. You may access your order history, wish lists, and more in the account area",
  },
  {
    question: "I've lost my password. What should I do? ",
    answer:
      "Enter your login or registered email address to obtain a new password. You should get the new password at the email address you provided during the signup. If you need assistance with your account details Monday to Friday between 9:00 am and 8:00 pm IST, you may contact customer support.",
  },
  {
    question: "How can I update my account?",
    answer:
      "Once logged in, select the Account link in the upper right corner to access your account page, where you may make any necessary changes. To change your account information, click the Edit option inside your account section. You can contact us Monday to Friday from 9:00 am to 8:00 pm IST if you have any issues with your account or want to cancel your service.",
  },
];

const fourthQandA = [
  {
    question:
      "What kinds of publications are available on RightSightResearch.com?",
    answer:
      "As an online platform, RightSightResearch.com provides access to various syndicated market studies. The market, industry, country, and company data in these investigations are both quantitative and qualitative. Every publication is unique in terms of report structure and length. Our reports are primarily independent studies from various market research organizations and may be purchased in a variety of forms, including PDF, paper copies, and CD-ROMs. There are also a variety of publications available.",
  },
  {
    question:
      "Who publishes the market research reports available on RightSightResearch.com?",
    answer:
      "RightSightResearch.com is a collection of market research studies written by the best companies all around the world.",
  },
  {
    question: "How much do publications cost?",
    answer:
      "There is no need to account for markups in publication pricing because they are decided by the publisher. Per our policy, we frequently collaborate with publishers to set up limited-time price reductions and promotions.",
  },
  {
    question: " How can I become a web publisher?",
    answer:
      "Fill out the publisher inquiry form or call us at _ if you're interested in having your publications delivered through our service.",
  },
];

const fifthQandA = [
  {
    question: "How do I go about ordering a publication?",
    answer:
      "Select the appropriate publication type from the drop-down menu and add it to your shopping cart by clicking the 'Add To Cart button'. The Shopping Cart area on the right of your screen will display each publication you have added to your Cart. Check out what you've got in your shopping cart. You may change your Shopping Cart by selecting new choices for each publication. After making your selections, proceed to the Checkout page.",
  },
  {
    question: "What methods of payment are acceptable? ",
    answer:
      "Credit cards and PayPal are both accepted for online purchases. Customer assistance is available from 9:00 am to 8:00 pm IST, Monday to Friday at __ to arrange wire transfers and invoices.",
  },
  {
    question: "When will I receive my order?",
    answer:
      "The publisher will email you a PDF version of the publication if it is available in that format. There are certain PDF publications that may be downloaded instantly from a website. Depending on the time zone and holidays, we will have the PDF version sent to your email address within 48 hours. When it comes to printed materials, we can guarantee a turnaround time of a maximum of a few weeks. Important Advice for New Customers: Please be aware that your contact information will be verified before your first order is shipped and that this may take up to one business day. Your initial purchase will be followed up with on the next working day if it took place on a weekend or holiday.",
  },
  {
    question: "How much does shipping cost?",
    answer:
      "If information is requested in hard copy form, additional shipping costs may be required. Customers pay for shipping and handling based on the total weight of their orders. However, it is optional to provide the exact cost of the fees. On the screen where you'll finalize your order after adding items to your shopping cart, you'll see the total shipping costs.",
  },
  {
    question: "What are different kinds of Licenses, and how are they used?",
    answer:
      "The PDF Single User License provides for the usage of a publication by a     single user. A single hard copy of the publication may be printed for the user's personal use, and while they may access the publication from any computer, they may not distribute or make available the publication or any of its contents to any other person. Every employee who needs access to the publication at their company must pay for their own Single User License unless the company buys a Global License. Invalid customers are responsible for a Global licensing charge.  Under the terms of a Global License, a publication may be used by a limitless amount of individuals inside the receiving organization, such as all of the employees of one company. This means they can access it from any computer and print off copies, but they can only provide the publication or any of the information in it to people within the company.",
  },
  {
    question: "Do you have a return policy? ",
    answer:
      "Since the publications have already been shipped, we are unable to issue refunds. Before placing a purchase, please be sure you have thoroughly researched the publication in the issue. Before placing an order, please feel free to get in touch with us if you have any doubts concerning the application or relevancy of a publication. RightSightResearch.com has the final say on any requests for refunds due to damaged or lost products. ",
  },
];
const sixthQandA = [
  {
    question: "How do you get data about me?",
    answer:
      "Your personal details (including name, email, company, contact info, address, and payment details) are collected when you register for an account or place an order. When you access the site, your IP address is also logged. To learn more, please review our complete guidelines on securing your privacy.",
  },
  {
    question: "How secure is my personal data?",
    answer:
      "When it comes to protecting your personal information, we always follow the rules as mentioned in our privacy policy. You have complete access to all of your personal data at any time by logging in and going to the 'Account' page. Follow the instructions in the email to unsubscribe from the service, or send an email to ___ with 'Remove Me' in the subject line if you no longer want to receive updates, newsletters, or promotional messages. ",
  },
  {
    question: "How does RightSightResearch.com maintain its safety?",
    answer:
      "To protect your information, we use a variety of safety features. The computers and servers storing your personal information are kept in specific parts of our premises. SSL (Secure Sockets Layer) protects all data in transit.",
  },
  {
    question: "How are cookies used?",
    answer:
      "A 'cookie' is an information file that is inactively kept on your computer. Your account details and the publications you've purchased in the past will be sent to RightSightResearch.com automatically. If you want to save time when using RightSightResearch.com and avoid having to log in every time, make sure your browser is set to accept cookies.",
  },
];
const QandAComponent = (props: {
  heading: string;
  description?: string;
  data: { question: string; answer: string }[];
}) => {
  const { heading, description, data } = props;
  const [openAnswers, setOpenAnswers] = useState(
    new Array(data.length).fill(false)
  );

  const handleQuesClick = (index: number) => {
    const newOpenAnswers = [...openAnswers];
    newOpenAnswers[index] = !newOpenAnswers[index];
    setOpenAnswers(newOpenAnswers);
  };

  return (
    <div className="q-and-a-component-root margin-horizontal-faqs-page">
      <div className="q-and-a-component-heading">{heading}</div>
      {description && (
        <div className="q-and-a-component-description">{description}</div>
      )}
      <ul>
        {data.map(({ question, answer }, index) => {
          return (
            <li className="question-answer-root" key={Math.random()}>
              <Button
                variant="link"
                onClick={() => handleQuesClick(index)}
                className="faq-question"
              >
                {question}{" "}
                <FontAwesomeIcon
                  icon={openAnswers[index] ? faChevronUp : faChevronDown}
                  size="xs"
                  style={{ marginLeft: 5 }}
                />
              </Button>
              {openAnswers[index] && (
                <div className="faq-answer-a">{answer}</div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Faqs = () => {
  return (
    <div className="faqs-root">
      <SEO
        title="FAQs - Right Sight Research"
        description="Find answers to frequently asked questions about our services and reports."
        canonicalUrl="https://www.rightsightresearch.com/faqs"
      />

      <PageHeader title="FAQs" />
      <QandAComponent
        heading="Who is RightSightResearch?"
        description="RightSightResearch.com is an innovative online marketplace for worldwide business intelligence that draws from a wide range of superior publishers' market research reports. If you need reliable market research studies to make essential business choices, go no further than RightSightResearch.com."
        data={firstQandA}
      />
      <QandAComponent heading="Use Our Website" data={secondQandA} />
      <QandAComponent
        heading="Account Creation & User Signup"
        data={thirdQandA}
      />
      <QandAComponent heading="Our Publications" data={fourthQandA} />
      <QandAComponent heading="Delivery & Ordering" data={fifthQandA} />
      <QandAComponent heading="Security & Privacy" data={sixthQandA} />
    </div>
  );
};

export default Faqs;
