import React, { useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader";
import PageHeader from "../../components/PageHeader";
import { CategoryContext } from "../../contexts/CategoryContext";
import "./categories.css";
import { formatString } from "../../utils/formatString";
import CategoryIcon from "../../components/CategoryIcon/CategoryIcon";
import SEO from "../../seo/SEO";
import { Link } from "react-router-dom";

interface CategoryCardProps {
  category: string;
  count: number;
}

const CategoryCard = ({ category, count }: CategoryCardProps) => {
  return (
    <Card className="category-card-container mb-1 mx-auto d-flex flex-row">
      <CategoryIcon
        categoryName={category}
        className="category-card-image p-1"
        style={{ color: "#000" }}
      />
      <div
        style={{
          flexGrow: 1,
          fontSize: "1.2em",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        {category}
      </div>
      <div
        style={{
          fontSize: "1em",
          color: "#999",
        }}
      >
        {count} reports
      </div>
    </Card>
  );
};

const Categories = () => {
  const { categories, isLoading } = useContext(CategoryContext);

  return (
    <>
      <SEO
        title="Categories - Right Sight Research"
        description="Browse through various categories of market research reports."
        canonicalUrl="https://www.rightsightresearch.com/categories"
      />

      <Loader isLoading={isLoading} />
      <PageHeader title="Categories" />
      <Row className="d-flex justify-content-center mx-auto mb-5 category-cards-container">
        {categories.map((category, index) => (
          <Col xs={12} md={6} lg={6} key={category.category}>
            <Link to={`/category/${formatString(category.category)}`}>
              <CategoryCard
                key={Math.random()}
                category={category.category}
                count={category.count}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Categories;
