import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import MainReportDetailHeader from "./MainReportDetailHeader";
import MainReportDetailNavigation from "./MainReportDetailNavigation";
import ReportDescription from "./ReportDescription";
import TableOfContent from "./TableOfContent";
import Enquiry from "../Enquiry";
import SampleReport from "../SampleReport";
import CheckDiscount from "../CheckDiscount";
import { TabType } from "../../../pages/ReportDetail";
import "./main-report-detail.css";

interface MainReportDetailProps {
  id: string;
  title: string;
  publishDate: Date;
  publisher: string;
  pages: number;
  category: string;
  description: string;
  tableOfContent: string;
  tab: TabType;
  setTab: React.Dispatch<React.SetStateAction<TabType>>;
}

const MainReportDetail = (props: MainReportDetailProps) => {
  const {
    id,
    title,
    publishDate,
    publisher,
    pages,
    category,
    description,
    tableOfContent,
    tab,
    setTab,
  } = props;
  const { tab: activeTab } = useParams<{ tab: TabType }>();
  const location = useLocation();

  const [downloadSelected, setDownloadSelected] = useState(false);

  useEffect(() => {
    if (activeTab && activeTab !== tab) {
      setTab(activeTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tab]);

  const onDownloadClick = () => {
    setTab('enquiry');
    setDownloadSelected(true);
  }

  return (
    <>
      <div className="row mb-2 main-report-detail-top">
        <div className="col-lg-4 col-md-4 col-sm-12 p-0">
          <div className="report-detail-text">Report Detail</div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 p-0">
          <div className="blink-me visible-md visible-lg">
            <button
              onClick={() => setTab("sample")}
              className="custom-header-panel"
            >
              Request Sample Report
            </button>
          </div>
        </div>
      </div>
      <div className="main-report-detail-root">
        <MainReportDetailHeader
          id={id}
          title={title}
          publishDate={publishDate}
          publisher={publisher}
          pages={pages}
          category={category}
          onDownloadClick={onDownloadClick}
        />
        <MainReportDetailNavigation setTab={setTab} tab={tab} id={id} />
        {tab === "description" && (
          <ReportDescription description={description} />
        )}
        {tab === "table" && <TableOfContent content={tableOfContent} />}
        {tab === "enquiry" && (
          <Enquiry
            link={location.pathname}
            id={id}
            title={title}
            description={description}
            tableOfContent={tableOfContent}
            downloadSelected={downloadSelected}
          />
        )}
        {tab === "sample" && <SampleReport id={id} link={location.pathname} />}
        {tab === "discount" && <CheckDiscount id={id} link={location.pathname} />}
      </div>
    </>
  );
};

export default MainReportDetail;
