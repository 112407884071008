export const countries = [
  { id: "1", name: "Afghanistan" },
  { id: "2", name: "Albania" },
  { id: "3", name: "Algeria" },
  { id: "4", name: "Andorra" },
  { id: "5", name: "Angola" },
  { id: "6", name: "Antigua & Barbuda" },
  { id: "7", name: "Argentina" },
  { id: "8", name: "Armenia" },
  { id: "9", name: "Australia" },
  { id: "10", name: "Austria" },
  { id: "11", name: "Azerbaijan" },
  { id: "12", name: "Bahamas" },
  { id: "13", name: "Bahrain" },
  { id: "14", name: "Bangladesh" },
  { id: "15", name: "Barbados" },
  { id: "16", name: "Belarus" },
  { id: "17", name: "Belgium" },
  { id: "18", name: "Belize" },
  { id: "19", name: "Benin" },
  { id: "20", name: "Bhutan" },
  { id: "21", name: "Bolivia" },
  { id: "22", name: "Bosnia & Herzegovina" },
  { id: "23", name: "Botswana" },
  { id: "24", name: "Brazil" },
  { id: "25", name: "Brunei" },
  { id: "26", name: "Bulgaria" },
  { id: "27", name: "Burkina Faso" },
  { id: "28", name: "Burundi" },
  { id: "29", name: "Cabo Verde" },
  { id: "30", name: "Cambodia" },
  { id: "31", name: "Cameroon" },
  { id: "32", name: "Canada" },
  { id: "33", name: "Central African Republic" },
  { id: "34", name: "Chad" },
  { id: "35", name: "Chile" },
  { id: "36", name: "China" },
  { id: "37", name: "Colombia" },
  { id: "38", name: "Comoros" },
  { id: "39", name: "Congo" },
  { id: "40", name: "Costa Rica" },
  { id: "41", name: "Croatia" },
  { id: "42", name: "Cuba" },
  { id: "43", name: "Cyprus" },
  { id: "44", name: "Czech Republic" },
  { id: "45", name: "Denmark" },
  { id: "46", name: "Djibouti" },
  { id: "47", name: "Dominica" },
  { id: "48", name: "Dominican Republic" },
  { id: "49", name: "Ecuador" },
  { id: "50", name: "Egypt" },
  { id: "51", name: "El Salvador" },
  { id: "52", name: "Equatorial Guinea" },
  { id: "53", name: "Eritrea" },
  { id: "54", name: "Estonia" },
  { id: "55", name: "Eswatini" },
  { id: "56", name: "Ethiopia" },
  { id: "57", name: "Fiji" },
  { id: "58", name: "Finland" },
  { id: "59", name: "France" },
  { id: "60", name: "Gabon" },
  { id: "61", name: "Gambia" },
  { id: "62", name: "Georgia" },
  { id: "63", name: "Germany" },
  { id: "64", name: "Ghana" },
  { id: "65", name: "Greece" },
  { id: "66", name: "Grenada" },
  { id: "67", name: "Guatemala" },
  { id: "68", name: "Guinea" },
  { id: "69", name: "Guinea-Bissau" },
  { id: "70", name: "Guyana" },
  { id: "71", name: "Haiti" },
  { id: "72", name: "Honduras" },
  { id: "73", name: "Hungary" },
  { id: "74", name: "Iceland" },
  { id: "75", name: "India" },
  { id: "76", name: "Indonesia" },
  { id: "77", name: "Iran" },
  { id: "78", name: "Iraq" },
  { id: "79", name: "Ireland" },
  { id: "80", name: "Israel" },
  { id: "81", name: "Italy" },
  { id: "82", name: "Jamaica" },
  { id: "83", name: "Japan" },
  { id: "84", name: "Jordan" },
  { id: "85", name: "Kazakhstan" },
  { id: "86", name: "Kenya" },
  { id: "87", name: "Kiribati" },
  { id: "88", name: "Kuwait" },
  { id: "89", name: "Kyrgyzstan" },
  { id: "90", name: "Laos" },
  { id: "91", name: "Latvia" },
  { id: "92", name: "Lebanon" },
  { id: "93", name: "Lesotho" },
  { id: "94", name: "Liberia" },
  { id: "95", name: "Libya" },
  { id: "96", name: "Liechtenstein" },
  { id: "97", name: "Lithuania" },
  { id: "98", name: "Luxembourg" },
  { id: "99", name: "Madagascar" },
  { id: "100", name: "Malawi" },
  { id: "101", name: "Malaysia" },
  { id: "102", name: "Maldives" },
  { id: "103", name: "Mali" },
  { id: "104", name: "Malta" },
  { id: "105", name: "Marshall Islands" },
  { id: "106", name: "Mauritania" },
  { id: "107", name: "Mauritius" },
  { id: "108", name: "Mexico" },
  { id: "109", name: "Micronesia" },
  { id: "110", name: "Moldova" },
  { id: "111", name: "Monaco" },
  { id: "112", name: "Mongolia" },
  { id: "113", name: "Montenegro" },
  { id: "114", name: "Morocco" },
  { id: "115", name: "Mozambique" },
  { id: "116", name: "Myanmar" },
  { id: "117", name: "Namibia" },
  { id: "118", name: "Nauru" },
  { id: "119", name: "Nepal" },
  { id: "120", name: "Netherlands" },
  { id: "121", name: "New Zealand" },
  { id: "122", name: "Nicaragua" },
  { id: "123", name: "Niger" },
  { id: "124", name: "Nigeria" },
  { id: "125", name: "North Korea" },
  { id: "126", name: "North Macedonia" },
  { id: "127", name: "Norway" },
  { id: "128", name: "Oman" },
  { id: "129", name: "Pakistan" },
  { id: "130", name: "Palau" },
  { id: "131", name: "Palestine" },
  { id: "132", name: "Panama" },
  { id: "133", name: "Papua New Guinea" },
  { id: "134", name: "Paraguay" },
  { id: "135", name: "Peru" },
  { id: "136", name: "Philippines" },
  { id: "137", name: "Poland" },
  { id: "138", name: "Portugal" },
  { id: "139", name: "Qatar" },
  { id: "140", name: "Romania" },
  { id: "141", name: "Russia" },
  { id: "142", name: "Rwanda" },
  { id: "143", name: "Saint Kitts & Nevis" },
  { id: "144", name: "Saint Lucia" },
  { id: "145", name: "Saint Vincent & the Grenadines" },
  { id: "146", name: "Samoa" },
  { id: "147", name: "San Marino" },
  { id: "148", name: "Sao Tome & Principe" },
  { id: "149", name: "Saudi Arabia" },
  { id: "150", name: "Senegal" },
  { id: "151", name: "Serbia" },
  { id: "152", name: "Seychelles" },
  { id: "153", name: "Sierra Leone" },
  { id: "154", name: "Singapore" },
  { id: "155", name: "Slovakia" },
  { id: "156", name: "Slovenia" },
  { id: "157", name: "Solomon Islands" },
  { id: "158", name: "Somalia" },
  { id: "159", name: "South Africa" },
  { id: "160", name: "South Korea" },
  { id: "161", name: "South Sudan" },
  { id: "162", name: "Spain" },
  { id: "163", name: "Sri Lanka" },
  { id: "164", name: "Sudan" },
  { id: "165", name: "Suriname" },
  { id: "166", name: "Sweden" },
  { id: "167", name: "Switzerland" },
  { id: "168", name: "Syria" },
  { id: "169", name: "Taiwan" },
  { id: "170", name: "Tajikistan" },
  { id: "171", name: "Tanzania" },
  { id: "172", name: "Thailand" },
  { id: "173", name: "Timor-Leste" },
  { id: "174", name: "Togo" },
  { id: "175", name: "Tonga" },
  { id: "176", name: "Trinidad & Tobago" },
  { id: "177", name: "Tunisia" },
  { id: "178", name: "Turkey" },
  { id: "179", name: "Turkmenistan" },
  { id: "180", name: "Tuvalu" },
  { id: "181", name: "Uganda" },
  { id: "182", name: "Ukraine" },
  { id: "183", name: "United Arab Emirates" },
  { id: "184", name: "United Kingdom" },
  { id: "185", name: "United States of America" },
  { id: "186", name: "Uruguay" },
  { id: "187", name: "Uzbekistan" },
  { id: "188", name: "Vanuatu" },
  { id: "189", name: "Vatican City (Holy See)" },
  { id: "190", name: "Venezuela" },
  { id: "191", name: "Vietnam" },
  { id: "192", name: "Yemen" },
  { id: "193", name: "Zambia" },
  { id: "194", name: "Zimbabwe" },
  { id: "195", name: "Abkhazia" },
  { id: "196", name: "Artsakh" },
  { id: "197", name: "Cook Islands" },
  { id: "198", name: "Kosovo" },
  { id: "199", name: "Niue" },
  { id: "200", name: "Northern Cyprus" },
  { id: "201", name: "Sahrawi Arab Democratic Republic" },
  { id: "202", name: "Somaliland" },
  { id: "203", name: "South Ossetia" },
  { id: "204", name: "Taiwan" },
  { id: "205", name: "Transnistria" },
  { id: "206", name: "Bonaire" },
  { id: "207", name: "Sint Eustatius" },
  { id: "208", name: "Saba" },
  { id: "209", name: "Ascension" },
  { id: "210", name: "Tristan da Cunha" },
  { id: "211", name: "Australian Antarctic Territory" },
  { id: "212", name: "Ross Dependency" },
  { id: "213", name: "Peter I Island" },
  { id: "214", name: "Queen Maud Land" },
  { id: "215", name: "British Antarctic Territory" },
  { id: "216", name: "French Southern and Antarctic Lands" },
  { id: "217", name: "Adélie Land" },
  { id: "218", name: "Clipperton Island" },
  { id: "219", name: "Crozet Islands" },
  { id: "220", name: "Kerguelen Islands" },
  { id: "221", name: "Saint Paul and Amsterdam islands" },
  { id: "222", name: "Scattered islands in the Indian Ocean" },
  { id: "223", name: "Heard Island and McDonald Islands" },
  { id: "224", name: "Macquarie Island" },
  { id: "225", name: "Ashmore and Cartier Islands" },
  { id: "226", name: "Coral Sea Islands" },
];
