import React from "react";
import "./picture-heading.css";

const PictureHeading = () => {
  return (
    <div className="picture-heading-root">
      <div className="picture-heading-text">
        ONE STOP SOLUTION FOR ALL YOUR MARKET RESEARCH NEEDS
      </div>
    </div>
  );
};

export default PictureHeading;
