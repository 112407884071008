import React, { useContext } from "react";
import "./popular-categories.css";
import { CategoryContext } from "../../../contexts/CategoryContext";
import Loader from "../../Loader";
import { formatString } from "../../../utils/formatString";
import CategoryIcon from "../../CategoryIcon/CategoryIcon";
import { Link } from "react-router-dom";

const PopularCategories: React.FC = () => {
  const { categories, isLoading } = useContext(CategoryContext);
  return (
    <div className="popular-categories-root">
      <Loader isLoading={isLoading} />
      <h4 className="popular-categories-root-title"> Popular Categories</h4>
      <ul>
        {categories.map((category) => (
          <li className="category" key={category.category}>
            <Link to={`/category/${formatString(category.category)}`}>
              <CategoryIcon
                categoryName={category.category}
                className="category-image"
              />
              &nbsp;&nbsp;{category.category}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularCategories;
