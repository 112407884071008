import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import "./admin-dashboard.css";
import Reports from "../../components/AdminDashboard/Reports";
import Blogs from "../../components/AdminDashboard/Blogs";
import { useNavigate } from "react-router-dom";
import { Button, Navbar, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import Messages from "../../components/AdminDashboard/Messages";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [selectedComponent, setSelectedComponent] = useState("reports");

  const handleLogout = () => {
    localStorage.removeItem("token");
    toast.success("Logged out successfully");
    navigate("/admin/login");
  };

  return (
    <div className="admin-dashboard-root">
      <Navbar bg="dark" variant="dark" className="admin-dashboard-header">
        <Navbar.Brand href="/admin" className="fs-3">
          Admin Dashboard
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="login-status">
            Logged in as: {localStorage.getItem("username")}
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
      <div className="columns-container">
        <Nav
          defaultActiveKey="/home"
          className="flex-column justify-content-between"
        >
          <div className="nav-links-container">
            <Nav.Link
              eventKey="link-1"
              onClick={() => setSelectedComponent("reports")}
              active={selectedComponent === "reports"}
            >
              Reports
            </Nav.Link>
            <Nav.Link
              eventKey="link-2"
              onClick={() => setSelectedComponent("blogs")}
              active={selectedComponent === "blogs"}
            >
              Blog
            </Nav.Link>
            <Nav.Link
              eventKey="link-2"
              onClick={() => setSelectedComponent("messages")}
              active={selectedComponent === "messages"}
            >
              Messages
            </Nav.Link>
          </div>
          <Button variant="dark" onClick={handleLogout} className="w-50 ">
            Logout
          </Button>
        </Nav>
        <div className="component-container">
          {selectedComponent === "reports" && <Reports />}
          {selectedComponent === "blogs" && <Blogs />}
          {selectedComponent === "messages" && <Messages />}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
