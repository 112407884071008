import { useState, useEffect } from "react";
import { countries } from "../../utils/countries";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { toast } from "react-toastify";
import { userInfoType } from "../../pages/Checkout";
import useIsMobile from "../../hooks/useIsMobile";
import { Link } from "react-router-dom";

interface CheckoutUserDetailsProps {
  setUserInfo: React.Dispatch<React.SetStateAction<userInfoType>>;
}

const CheckoutUserDetails = (props: CheckoutUserDetailsProps) => {
  const isMobile = useIsMobile();
  const { setUserInfo } = props;
  const [captchaInput, setCaptchaInput] = useState("");

  const onCaptchaInput = (e: any) => {
    setCaptchaInput(e.target.value);
  };

  const verifyClick = () => {
    try {
      const isValid = validateCaptcha(captchaInput, true);
      if (isValid) {
        toast.success("CAPTCHA verified successfully!");
        setUserInfo((prevState) => ({
          ...prevState,
          isCaptchaVerified: true,
        }));
      } else {
        toast.error("CAPTCHA not correct. Please enter again.");
      }
    } catch (error) {
      toast.error("CAPTCHA not correct. Please enter again.");
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <>
      <div className="panel panel-default">
        <div
          className="panel-heading"
          style={{
            background: "#2f6e89",
            textAlign: "center",
            borderRadius: 0,
          }}
        >
          <h4 style={{ color: "#fff", padding: 8, fontSize: 20 }}>
            User Information
          </h4>
        </div>
        <div className="panel-body">
          <div className="form-group">
            <div className="row">
              <div className="col-sm-6">
                <label
                  className="control-label"
                  style={{ marginLeft: "5px", marginTop: "20px" }}
                >
                  First Name:
                </label>

                <input
                  name="firstName"
                  onChange={handleChange}
                  className="form-control"
                  placeholder="First Name"
                  type="text"
                  style={{ margin: 5 }}
                />
              </div>
              <div className="col-sm-6">
                <label
                  className="control-label"
                  style={{
                    marginLeft: "5px",
                    margin: isMobile ? "5px" : "",
                    marginTop: isMobile ? "5px" : "20px",
                  }}
                >
                  Last Name:<span className="star">*</span>
                </label>

                <input
                  className="form-control"
                  name="lastName"
                  onChange={handleChange}
                  placeholder="Last Name"
                  type="text"
                  style={{ margin: 5 }}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-sm-6">
                <label className="control-label" style={{ margin: 5 }}>
                  Email address:<span className="star">*</span>
                </label>

                <input
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  type="text"
                  style={{ margin: 5 }}
                />
              </div>
              <div className="col-sm-6">
                <label className="control-label" style={{ margin: 5 }}>
                  Phone Number:<span className="star">*</span>
                </label>

                <input
                  className="form-control"
                  name="phoneNumber"
                  onChange={handleChange}
                  placeholder="Phone Number:"
                  type="text"
                  style={{ margin: 5 }}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-sm-6">
                <label className="control-label" style={{ margin: 5 }}>
                  Job Title:<span className="star">*</span>
                </label>

                <input
                  className="form-control"
                  name="jobTitle"
                  onChange={handleChange}
                  placeholder="Job Title"
                  type="text"
                  style={{ margin: 5 }}
                />
              </div>
              <div className="col-sm-6">
                <label className="control-label" style={{ margin: 5 }}>
                  Your Company:<span className="star">*</span>
                </label>

                <input
                  className="form-control"
                  name="companyName"
                  onChange={handleChange}
                  placeholder="Company Name"
                  type="text"
                  style={{ margin: 5 }}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-sm-6">
                <label className="control-label">
                  Country:<span className="star">*</span>
                </label>

                <select
                  className="form-control"
                  name="country"
                  onChange={handleChange}
                  style={{ margin: 5 }}
                >
                  <option value="">--select country--</option>
                  {countries.map((country) => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-6">
                <label className="control-label" style={{ margin: 5 }}>
                  Comments:
                </label>

                <textarea
                  className="form-control"
                  name="comments"
                  onChange={handleChange}
                  placeholder="Any Comments"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-4 pb-4">
          <LoadCanvasTemplate reloadColor="red" reloadText={`Reload`} />
          <div className="d-flex flex-column align-items-start gap-2">
            <input
              onChange={onCaptchaInput}
              placeholder="Enter captcha"
              className="px-2 py-2 fs-6"
              style={{ width: 140 }}
            />
            <button onClick={() => verifyClick()} className="btn btn-warning ">
              Verify
            </button>
          </div>
        </div>
        <div className="mb-4 mx-4">
          <input
            type="checkbox"
            name="hasAcceptedTerms"
            onChange={handleCheckboxChange}
          />
          &nbsp;&nbsp;I read and Accept the
          <Link
            to="/terms-and-conditions"
            target="_blank"
            referrerPolicy="no-referrer"
          >
            Terms and Conditions
          </Link>
        </div>
      </div>
    </>
  );
};

export default CheckoutUserDetails;
