import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const getMessages = async () => {
  return await axios.get(`${BASE_URL}/api/messages`);
};

export const getMessage = async (id: string) => {
  return await axios.get(`${BASE_URL}/api/messages/${id}`);
};

export const getMessagesByType = async (messageType: string) => {
  return await axios.get(`${BASE_URL}/api/messages/type/${messageType}`);
};

export const postMessage = async (data: any) => {
  return await axios.post(`${BASE_URL}/api/messages`, data);
};

export const deleteMessage = async (id: number) => {
  return await axios.delete(`${BASE_URL}/api/messages/${id}`);
};
