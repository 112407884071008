import React from 'react'

interface ReportDescriptionProps {
  description: string;
}

const ReportDescription = (props: ReportDescriptionProps) => {
  const {description} = props;
  return (
    <pre style={{textAlign: 'justify'}}>{description}</pre>
  )
}

export default ReportDescription