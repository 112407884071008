import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faSquareTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faRss } from "@fortawesome/free-solid-svg-icons";
import "./sub-footer.css";
import { Link } from "react-router-dom";

const SubFooter = () => {
  return (
    <div className="subfooter-container">
      <div className="copyright-text">
        Copyright © 2023, All Rights Reserved, rightsightresearch.com
      </div>
      <div className="pay-images">
        <img
          src={require("../../assets/visa-vector-logo.jpg")}
          alt="visa-icon"
        />
        <img
          src={require("../../assets/mastercard-logo.png")}
          alt="mastercard-logo"
        />
        <img
          src={require("../../assets/amercan-express.png")}
          alt="american-express-logo"
        />
        <img src={require("../../assets/PayPal.jpg")} alt="paypal-logo" />
      </div>
      <div className="socials-icons">
        <Link
          to="https://www.facebook.com/profile.php?id=61551581332537"
          target="_blank"
          className="icon"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" color="white" />
        </Link>
        <Link
          to="https://twitter.com/rightsightrsch"
          target="_blank"
          className="icon"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faSquareTwitter} size="2x" color="white" />
        </Link>
        <Link
          to="https://www.linkedin.com/company/right-sight-market-research-pvt-ltd"
          target="_blank"
          className="icon"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" color="white" />
        </Link>
        <Link
          to="https://facebook.com"
          target="_blank"
          className="icon"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faRss} size="2x" color="white" />
        </Link>
      </div>
    </div>
  );
};

export default SubFooter;
