import React from "react";
import SEO from "../../seo/SEO";

const NotFoundPage: React.FC = () => {
  return (
    <div style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}>
      <SEO
        title="404 Not Found - Right Sight Research"
        description="The page you are looking for does not exist."
        canonicalUrl="https://www.rightsightresearch.com/404"
      />

      <h1>404</h1>
      <h5>Page Not Found</h5>
    </div>
  );
};

export default NotFoundPage;
