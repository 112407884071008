import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getReport } from "../../api/reports";
import { Report } from "../../types/@types";
import {
  USD_TO_EUR,
  USD_TO_GBP,
  USD_TO_INR,
  USD_TO_JPY,
} from "../../utils/constants";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { formatTitle } from "../../utils/formatTitle";
import CategoryIcon from "../CategoryIcon/CategoryIcon";
import "../../pages/Checkout/checkout.css";

const CheckoutDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const report_id = query.get("report_id");
  const currency = query.get("currency");
  const license = query.get("license");
  const [reportData, setReportData] = useState<Report>({} as Report);
  const [isLoading, setIsLoading] = useState(false);

  const getCurrencySymbol = () => {
    if (currency === "USD") return "$";
    if (currency === "GBP") return "£";
    if (currency === "EUR") return "€";
    if (currency === "JPY") return "¥";
    if (currency === "INR") return "₹";
  };

  const getLicensePrice = () => {
    if (reportData.oneUserPDF && license === "1-User_License")
      return reportData.oneUserPDF;
    if (reportData.fiveUserPDF && license === "5-User_License")
      return reportData.fiveUserPDF;
    if (reportData.sitePDF && license === "Site_PDF") return reportData.sitePDF;
    if (reportData.enterprisePDF && license === "Enterprise_License")
      return reportData.enterprisePDF;
    else return 0;
  };

  const getReportPrice = () => {
    if (currency === "USD") return getLicensePrice();
    if (currency === "GBP") return getLicensePrice() * USD_TO_GBP;
    if (currency === "EUR") return getLicensePrice() * USD_TO_EUR;
    if (currency === "JPY") return getLicensePrice() * USD_TO_JPY;
    if (currency === "INR") return getLicensePrice() * USD_TO_INR;
  };

  useEffect(() => {
    if (!report_id) return;

    (async () => {
      try {
        const report = await getReport(report_id);
        setReportData(report.data);
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while fetching the report.");
      } finally {
        setIsLoading(false);
      }
    })();
  }, [report_id]);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <Loader isLoading={isLoading} />
      <div className="checkout">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th> &nbsp; </th>
              <th style={{ fontSize: 18 }}>Report Title</th>
              <th style={{ fontSize: 18 }}>Price</th>
              <th style={{ fontSize: 18 }}>Quantity</th>
              <th style={{ fontSize: 18 }}>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                {reportData.category && (
                  <CategoryIcon
                    className="img-thumbnail img-responsive"
                    categoryName={reportData.category}
                  />
                )}
              </td>
              <td>
                <p style={{ fontSize: 16 }}>
                  {reportData.title && formatTitle(reportData.title)}
                </p>
                <p>
                  <b className="datecolor">Edition: </b>
                  {license}
                </p>
                <p>
                  <b className="datecolor">Publisher: </b>
                  {reportData.publisher}
                </p>
              </td>
              <td width="12%">
                {getCurrencySymbol()} {getReportPrice()}
              </td>
              <td>1</td>
              <td width="12%">
                {getCurrencySymbol()} {getReportPrice()}
              </td>
            </tr>

            <tr>
              <td colSpan={3}></td>
              <td>Total:</td>
              <td width="12%">
                <strong style={{ fontSize: 16 }}>
                  {getCurrencySymbol()} {getReportPrice()}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CheckoutDetails;
