import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Navbar } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import "./admin-login.css";

const AdminLogin = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/admin");
    }
  }, [navigate]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + "/api/admin/login",
        {
          username,
          email,
          password,
        }
      );
      navigate("/admin");
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", response.data.username);
      toast.success(response.data.message);
    } catch (error) {
      console.error(error);
      toast.error("Invalid credentials");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-login-root">
      <Navbar bg="dark" variant="dark" className="admin-login-header">
        <Navbar.Brand href="/" className="fs-3">
          Admin Login
        </Navbar.Brand>
      </Navbar>
      <Loader isLoading={isLoading} />
      <Row className="justify-content-md-center align-items-center login-container">
        <Col md="auto">
          <Form onSubmit={handleSubmit} className="admin-login-form">
            <h2 className="login-title">Login</h2>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="email" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="password" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button variant="dark" type="submit" className="w-100 ">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AdminLogin;
