import React, { createContext, useState, useEffect } from "react";
import { getCategories } from "../api/reports";

interface ICategoryContext {
  categories: any[];
  isLoading: boolean;
}

interface ReportsProviderProps {
  children: React.ReactNode;
}

export const CategoryContext = createContext<ICategoryContext>({
  categories: [],
  isLoading: true,
});

export const CategoryProvider: React.FC<ReportsProviderProps> = ({
  children,
}) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getCategories();
        setCategories(response.data);
      } catch (error) {
        console.error("Failed to fetch categories", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <CategoryContext.Provider value={{ categories, isLoading }}>
      {children}
    </CategoryContext.Provider>
  );
};
