import {
  faChevronDown,
  faChevronRight,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { formatString } from "../../utils/formatString";
import { CategoryContext } from "../../contexts/CategoryContext";
import Loader from "../Loader";
import { Link } from "react-router-dom";

interface MobileHeaderProps {
  isMenuOpen: boolean;
  handleMenuClick: () => void;
}

const MobileHeader = (props: MobileHeaderProps) => {
  const { isMenuOpen, handleMenuClick } = props;
  const { categories, isLoading } = useContext(CategoryContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);

  const toggleShowServicesDropdown = () => {
    setShowServicesDropdown(!showServicesDropdown);
  };

  const toggleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <Modal show={isMenuOpen} centered fullscreen className="menu-bar-container">
      <Loader isLoading={isLoading} />
      <Button
        style={{ backgroundColor: "#055160" }}
        className="menu-bar-mobile-close"
        onClick={handleMenuClick}
      >
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Link to="/" onClick={handleMenuClick}>
        Home
      </Link>
      <Link to="/about-us" onClick={handleMenuClick}>
        About Us
      </Link>
      <button
        onClick={toggleShowServicesDropdown}
        className="all-categories-btn btn btn-link"
      >
        Our Services
        <FontAwesomeIcon
          icon={showServicesDropdown ? faChevronDown : faChevronRight}
          size="xs"
        />
      </button>
      {showServicesDropdown && (
        <div className="services-dropdown-container">
          <Link to="/syndicated-reports" onClick={handleMenuClick}>Syndicated Reports</Link>

          <Link to="/consulting-services" onClick={handleMenuClick}>Consulting Services</Link>
          <Link to="/customized-research" onClick={handleMenuClick}>Customized Research</Link>
        </div>
      )}

      <button
        onClick={toggleShowDropdown}
        className="all-categories-btn btn btn-link"
      >
        All Categories
        <FontAwesomeIcon
          icon={showDropdown ? faChevronDown : faChevronRight}
          size="xs"
        />
      </button>
      {showDropdown && (
        <div className="dropdown-container">
          {categories.map((category) => (
            <Link
              key={category.category}
              to={`/category/${formatString(category.category)}`}
              onClick={handleMenuClick}
            >
              {category.category}
            </Link>
          ))}
        </div>
      )}

      <Link to="/all-reports" onClick={handleMenuClick}>
        All Reports
      </Link>
      <Link to="/become-publisher" onClick={handleMenuClick}>
        Become Publisher
      </Link>
      <Link to="/blogs" onClick={handleMenuClick}>
        Blog
      </Link>
      <Link to="/contact-us" onClick={handleMenuClick}>
        Contact Us
      </Link>
    </Modal>
  );
};

export default MobileHeader;
