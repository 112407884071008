import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import PurchaseReport from "../../components/ReportDetail/PurchaseReport";
import MainReportDetail from "../../components/ReportDetail/MainReportDetail";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import RelatedReports from "../../components/ReportDetail/RelatedReports";
import { ReportsContext } from "../../contexts/ReportsContext";
import SEO from "../../seo/SEO";
import { formatUrlString } from "../../utils/formatUrlString";

export type TabType =
  | "description"
  | "table"
  | "sample"
  | "enquiry"
  | "discount";

const ReportDetail: React.FC = () => {
  const { id } = useParams<{ reportTitle: string; id: string }>();
  const { fetchReport, isLoading, report } = useContext(ReportsContext);
  const [tab, setTab] = useState<TabType>("description");

  useEffect(() => {
    if (!id) return;

    if (window.location.hash === "#sample") {
      setTab("sample");
    }

    (async () => {
      try {
        await fetchReport(id);
      } catch (error) {
        console.error(error);
        toast.error("No such report exists");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="row margin-horizontal-report-detail-page">
      <SEO
        title={`${report.title} - Right Sight Research`}
        description="Detailed insights and analysis on the latest market trends."
        canonicalUrl={`https://www.rightsightresearch.com/reports/${formatUrlString(report.title)}/${id}`}
      />

      <Loader isLoading={isLoading} />
      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
        <MainReportDetail
          id={id || ""}
          title={report?.title}
          publishDate={report?.publishDate}
          publisher={report?.publisher}
          pages={report?.pages}
          category={report?.category}
          description={report?.description}
          tableOfContent={report?.tableOfContent}
          tab={tab}
          setTab={setTab}
        />
      </div>
      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <PurchaseReport
          oneUserPDF={report.oneUserPDF}
          fiveUserPDF={report.fiveUserPDF}
          sitePDF={report.sitePDF}
          enterprisePDF={report.enterprisePDF}
          id={id || ""}
        />
        <RelatedReports reportId={id || ""} />
      </div>
    </div>
  );
};

export default ReportDetail;
