import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { countries } from "../../../utils/countries";
import { postMessage } from "../../../api/messages";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import "../report-detail-form.css";
import useIsMobile from "../../../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";

interface CheckDiscountProps {
  link: string;
  id: string;
}

const CheckDiscount = (props: CheckDiscountProps) => {
  const router = useNavigate();
  const isMobile = useIsMobile();
  const { link, id } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    if (!firstName) return true;
    if (!lastName) return true;
    if (!email) return true;

    if (!jobTitle) return true;
    if (!company) return true;
    if (!phoneNumber) return true;

    if (!country) return true;
    if (!question) return true;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (validateForm())
      return toast.error("Please fill all the required fields.");

    const messageData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      jobTitle,
      company,
      country,
      question,
      messageType: "check-discount",
      linkToReport: link,
    };

    setIsLoading(true);

    try {
      await postMessage(messageData);

      router(`/thankyou/check-discount/${id}`)

      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setJobTitle("");
      setCompany("");
      setCountry("");
      setQuestion("");
      toast.success("Message sent successfully!");
    } catch (error) {
      toast.error("Failed to send message:" + error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Loader isLoading={isLoading} />
      <div className="report-detail-form-form-container">
        <div className="report-detail-form-text-btn">
          <h5 className="report-detail-form-txt">
            Check Discount on this report
          </h5>
          <Button
            className="align-self-end"
            href=""
            size="sm"
            variant="warning"
          >
            View Report
          </Button>
        </div>

        <div className="report-detail-form-form">
          <form onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Name:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      style={{ width: 200, marginRight: isMobile ? 0 : 5 }}
                      className="report-detail-form-input"
                      placeholder="First Name"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />

                    <input
                      style={{ width: isMobile ? 200 : 195 }}
                      className="report-detail-form-input"
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Email:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      className="report-detail-form-input"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Job Title:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      className="report-detail-form-input"
                      placeholder="Job Title"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Company:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      className="report-detail-form-input"
                      placeholder="Company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Phone No:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      className="report-detail-form-input"
                      placeholder="Phone No"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Country:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <select
                      className="report-detail-form-select-country"
                      id="country_id"
                      name="country_id"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">--select country--</option>
                      {countries.map((country) => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Your Question:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <textarea
                      rows={4}
                      className="report-detail-form-textArea"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="report-detail-form-submit-btn">
              <Button variant="success" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckDiscount;
