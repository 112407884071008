import React, { createContext, useState, useEffect } from "react";
import { getPublishers } from "../api/reports";

interface IPublisherContext {
  publishers: any[];
  isLoading: boolean;
}

interface ReportsProviderProps {
  children: React.ReactNode;
}

export const PublisherContext = createContext<IPublisherContext>({
  publishers: [],
  isLoading: true,
});

export const PublisherProvider: React.FC<ReportsProviderProps> = ({
  children,
}) => {
  const [publishers, setPublishers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getPublishers();
        setPublishers(response.data);
      } catch (error) {
        console.error("Failed to fetch publishers", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <PublisherContext.Provider value={{ publishers, isLoading }}>
      {children}
    </PublisherContext.Provider>
  );
};
