import React, { createContext, useState, useEffect } from "react";
import {
  getBlogsData,
  getBlog,
  postBlog,
  deleteBlog,
  updateBlog,
} from "../api/blog";
import { Blog } from "../types/@types";

interface IBlogContext {
  blogs: Blog[];
  isLoading: boolean;
  getBlog: (id: string) => Promise<any>;
  postBlog: (data: any) => Promise<any>;
  deleteBlog: (id: number) => Promise<any>;
  updateBlog: (id: number, data: any) => Promise<any>;
}

interface BlogProviderProps {
  children: React.ReactNode;
}

export const BlogContext = createContext<IBlogContext>({
  blogs: [],
  isLoading: true,
  getBlog: async (id: string) => {},
  postBlog: async (data: any) => {},
  deleteBlog: async (id: number) => {},
  updateBlog: async (id: number, data: any) => {},
});

export const BlogProvider: React.FC<BlogProviderProps> = ({ children }) => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getBlogsData();
        setBlogs(response.data);
      } catch (error) {
        console.error("Failed to fetch blogs", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const getBlogById = async (id: string) => {
    return await getBlog(id);
  };

  const postNewBlog = async (data: any) => {
    await postBlog(data);
    setBlogs([data, ...blogs]);
  };

  const deleteExistingBlog = async (id: number) => {
      await deleteBlog(id);
      setBlogs(blogs.filter((blog) => blog._id !== id));
  };

  const updateExistingBlog = async (id: number, data: any) => {
    await updateBlog(id, data);
    const updatedBlogs = blogs.map((blog) => (blog._id === id ? data : blog));
    setBlogs(updatedBlogs);
  };

  return (
    <BlogContext.Provider
      value={{
        blogs,
        isLoading,
        getBlog: getBlogById,
        postBlog: postNewBlog,
        deleteBlog: deleteExistingBlog,
        updateBlog: updateExistingBlog,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};
