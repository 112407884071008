import React, { useState, useContext } from "react";
import { Button } from "react-bootstrap";
import "./header.css";
import { CategoryContext } from "../../contexts/CategoryContext";
import { formatString } from "../../utils/formatString";
import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import MobileHeader from "./MobileHeader";
import useIsMobile from "../../hooks/useIsMobile";
import { Link } from "react-router-dom";

const Header = () => {
  const isMobile = useIsMobile();
  const { categories, isLoading } = useContext(CategoryContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => setIsMenuOpen(!isMenuOpen);
  const indiaFlagImg = require("../../assets/india-flag-icon.png");
  const usaFlagImg = require("../../assets/american-us-flag-icon-img.png");

  return (
    <header>
      <Loader isLoading={isLoading} />
      <div className="main-header-container">
        <div className="col-desktop-5 col-phone-6 col-phonemin-8 header-main-logo-container">
          <Link id="header-main-logo" to="/">
            <span className="logo">
              <img
                src={require("../../assets/rsr-logo.png")}
                alt="Logo RightSightResearch"
              />
            </span>
          </Link>
        </div>
        <div className="menu-bar-root">
          <div className="email-contact-root">
            <div className="ind-number">
              <img src={indiaFlagImg} alt="india flag" className="ind-flag" />
              <Link to="tel:+918591141509" className="email-header">
                +91 85911 41509
              </Link>
            </div>
            <div className="usa-number">
              <img src={usaFlagImg} alt="usa-flag" className="usa-flag" />
              <Link to="tel:+14154495492" className="email-header">
                +1 415 449 5492
              </Link>
            </div>
            <div className="usa-number">
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
              <Link
                to="mailto:info@rightsightresearch.com"
                className="email-header"
              >
                info@rightsightresearch.com
              </Link>
            </div>
          </div>
          {isMobile ? (
            <>
              <Button style={{ backgroundColor: "#055160" }}>
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={handleMenuClick}
                  className="menu-bar-mobile-bars"
                />
              </Button>
              <MobileHeader
                isMenuOpen={isMenuOpen}
                handleMenuClick={handleMenuClick}
              />
            </>
          ) : (
            <div className={`menu-bar-container`}>
              <Link to="/">Home</Link>
              <Link to="/about-us">About Us</Link>
              <Link
                onMouseEnter={() => setShowServicesDropdown(true)}
                onMouseLeave={() => setShowServicesDropdown(false)}
                className="all-categories-btn"
                to="/"
              >
                Our Services
              </Link>
              {showServicesDropdown && (
                <div
                  onMouseEnter={() => setShowServicesDropdown(true)}
                  onMouseLeave={() => setShowServicesDropdown(false)}
                  className="services-dropdown-container"
                >
                  <Link to="/syndicated-reports">Syndicated Reports</Link>

                  <Link to="/consulting-services">Consulting Services</Link>
                  <Link to="/customized-research">Customized Research</Link>
                </div>
              )}

              <Link
                onMouseEnter={() => setShowDropdown(true)}
                onMouseLeave={() => setShowDropdown(false)}
                className="all-categories-btn"
                to="/categories"
              >
                All Categories
              </Link>
              {showDropdown && (
                <div
                  onMouseEnter={() => setShowDropdown(true)}
                  onMouseLeave={() => setShowDropdown(false)}
                  className="dropdown-container"
                >
                  {categories.map((category) => (
                    <Link
                      key={category.category}
                      to={`/category/${formatString(category.category)}`}
                    >
                      {category.category}
                    </Link>
                  ))}
                </div>
              )}

              <Link to="/all-reports">All Reports</Link>
              <Link to="/become-publisher">Become Publisher</Link>
              <Link to="/blogs">Blog</Link>
              <Link to="/contact-us">Contact Us</Link>
            </div>
          )}
        </div>
      </div>
      <hr style={{ margin: 0 }} />
    </header>
  );
};

export default Header;
