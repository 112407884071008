import React from "react";
import "./about-us.css";
import SEO from "../../seo/SEO";

const AboutUs = () => {
  return (
    <div className="about-us-container margin-horizontal-about-us">
      <SEO
        title="About US | Right Sight Market Research Pvt Ltd."
        description="Want to know more about right sight market research? We are one of the leading market research companies based in Navi Mumbai. Helping businesses get industry insights."
        canonicalUrl="https://www.rightsightresearch.com/about-us"
      />

      <div className="title-abt-us">About Us</div>
      <div className="p-about-us">
        <div className="p-about-us">
          In a data-driven world, information is very important. We at
          RightSightResearch.com offer a wide range of market research reports
          and advisory services that go into detail about industry trends,
          customer behavior, competitive analysis, and more. If you're looking
          for the most up-to-date information in your field, you'll find it in
          our vast collection of reports, which covers a varied range of
          industries.
        </div>
        <div className="p-about-us">
          With the help of our market research services, businesses will be able
          to better understand their consumers and the market as a whole,
          allowing them to capitalize on growth possibilities and develop a
          competitive advantage more efficiently.
        </div>
        <div className="p-about-us">
          We use a challenging, comprehensive market research approach to cover
          more territory than any competitor.
        </div>
      </div>

      <div className="abt-us-head">
        Comprehensive Market Research Consulting Services from
        RightSightResearch.com &nbsp;
      </div>
      <div className="p-about-us">
        <div className="p-about-us">
          Don't miss out on valuable insights. Maximize your business potential
          and empower your business with RightSightResearch.com's industry
          expertise. Our expertise in market research consulting includes the
          use of both primary and secondary research methods.
        </div>
        <div className="p-about-us">
          When it comes to generating specialized market research data and
          relevant insights, RightSightResearch's team of market research
          outsourcing professionals and consultants specializes in reading the
          small print from underlying data. We use modern strategies in our
          market research solutions to better present our results.
        </div>
      </div>

      <div className="abt-us-head">
        Superior Knowledge and Understanding &nbsp;
      </div>

      <div className="p-about-us">
        <div className="p-about-us">
          Our expert analysts and consultants work constantly to provide you
          with helpful information and guidance. They have an in-depth
          understanding of the market and can help you find development
          possibilities and reduce risks. We appreciate that every company is
          different, which is why we offer customized services.
        </div>
      </div>
      <div className="abt-us-head">Dedication to Quality</div>
      <div className="p-about-us">
        <div className="p-about-us">
          All of our efforts are focused on maximizing quality. To guarantee
          that the research we produce is of the most excellent quality, our
          team of professionals uses strict quality control techniques. We are
          committed to providing you with fast, accurate, and valuable
          information so that you may make intelligent choices.
        </div>
      </div>
      <div className="abt-us-head">Market intelligence platform helps:</div>
      <div className="list-about-us">
        <div className="list-about-us">
          <li>Competitor and industry comparisons.</li>
          <li>Competitive strategy and market share. </li>
          <li> Regional market opportunities. </li>
          <li> Find new market dynamics and trends. </li>
          <li> Quality market research.</li>
        </div>
      </div>

      <div className="abt-us-head">Why Choose Us</div>
      <div className="p-about-us">
        <div className="p-about-us">
          Working with RightSightResearch.com to build your business gives you
          an advantage over competitors. We are able to give you a significant
          competitive advantage in the market because of our in-depth
          understanding of your business, our broad research skills, and our
          dedication to providing excellent service. &nbsp; &nbsp; form and
          allow us to contact you or simply give a call us on
        </div>
      </div>

      <div className="list-about-us">
        <div className="list-about-us">
          <li>Personal Attention </li>
          <li> Established Protocol</li>
          <li>Tailored Services</li>
          <li>Satisfaction Guarantee </li>
          <li> Seasoned Experts</li>
          <li>Analytical Flexibility </li>
          <li>Track Record of Excellence</li>
        </div>
      </div>
      <div className="p-about-us">
        <div className="p-about-us">
          Finding a reliable partner to help with market research and direction
          may be challenging due to the need to maintain a balance between
          accuracy, responsiveness, and the availability of resources, all while
          making sure the vendor you choose will provide you with transparent,
          actionable information.
        </div>
        <div className="p-about-us">
          Trust us to be your strategic insights partner. We'll help you manage
          the market's challenges and reach your business goals.
        </div>
      </div>
      <div className="abt-us-head">
        <div>
          <li>International clients</li>
        </div>
      </div>
      <div className="p-about-us">
        <div className="p-about-us">
          We have helped clients from different countries so far. From our point
          of view, we can see what's happening in your specific market as well
          as what's successful in other parts of the world.
        </div>
      </div>
      <div className="abt-us-head">
        <div>
          <li>We've worked with comparable companies.</li>
        </div>
      </div>
      <div className="p-about-us">
        <div className="p-about-us">
          <b> RightSightResearch</b> has successfully executed a wide variety of
          consulting projects for clients in a variety of sectors. That's good
          news because it implies we've handled a situation quite similar to
          yours before.
        </div>
      </div>
      <div className="abt-us-head">What we offer</div>
      <div className="p-about-us">
        <div className="p-about-us">
          The experts at RightSightResearch.com are masters in developing
          cutting-edge plans to boost productivity, revenue, and customer
          satisfaction. In order to achieve business excellence, our business
          intelligence consultants devise a multi-channel marketing plan based
          on constant market evaluation, with a particular focus on costs and
          unique products and services. Business is different from what it was
          and will be in the future. Therefore, we develop company plans that
          can easily be adapted to new scenarios and are based on the latest
          research.
        </div>
      </div>
      <div className="list-about-us">
        <div className="list-about-us">
          <li>
            Explore potential avenues for growth, conduct in-depth analyses of
            the local and international markets based on our evaluation of
            product suitability, and plan out acquisition and collaboration
            strategies.
          </li>
          <li>
            Expert market research consultants integrate digitalization into
            business plans to find gaps, understand customers, and increase
            revenue with the latest technology.
          </li>
          <li>
            Analyzing the present sales and marketing plan with an eye for
            enhancing collaboration and using automation to achieve significant
            success
          </li>
        </div>
      </div>
      <div className="abt-us-head">
        Take Your Business to New Heights with RightSightResearch.com - Get in
        Touch Now
      </div>
      <div className="p-about-us">
        <div className="p-about-us">
          When you work with RightSightResearch, you get access to a group of
          professionals that are passionate about providing you with insights
          that you can use to move your business ahead. As the number one choice
          for market research reports and consulting services, we pride
          ourselves on our dedication to excellence, deep understanding of the
          industry, and ability to provide customized solutions.
        </div>
        <div className="p-about-us">
          Contact us now to see how RightSightResearch.com can help your company
          reach its goals by providing the relevant market intelligence it
          requires.
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
