import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWheatAwn,
  faCar,
  faFlask,
  faTruck,
  faMobile,
  faLightbulb,
  faToolbox,
  faUtensils,
  faIndustry,
  faGlobe,
  faSuitcaseMedical,
  faPrescription,
  faUserTie,
  faFile,
  faPlane,
  faBrain,
  faComputer,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

interface CategoryIconProps {
  categoryName: string;
  className?: string;
  style?: React.CSSProperties;
}

type CategoryIconsMap = { [key: string]: IconDefinition };

const categoryIcons: CategoryIconsMap = {
  aerospace: faPlane,
  agriculture: faWheatAwn,
  intelligence: faBrain,
  automobile: faCar,
  chemical: faFlask,
  goods: faTruck,
  electronics: faMobile,
  energy: faLightbulb,
  equipment: faToolbox,
  food: faUtensils,
  healthcare: faSuitcaseMedical,
  internet: faGlobe,
  material: faIndustry,
  medical: faSuitcaseMedical,
  pharma: faPrescription,
  service: faUserTie,
  manufacturing: faIndustry,
  construction: faIndustry,
  ict: faComputer,
};

const CategoryIcon: React.FC<CategoryIconProps> = ({
  categoryName,
  className,
  style,
}) => {
  let icon: IconDefinition = faFile;

  const lowerCasedCategoryName = categoryName.toLowerCase();

  Object.keys(categoryIcons).forEach((key) => {
    if (lowerCasedCategoryName.includes(key)) {
      icon = categoryIcons[key];
    }
  });

  return <FontAwesomeIcon icon={icon} className={className} style={style} />;
};

export default CategoryIcon;
