import React, { createContext, useState, useCallback, useEffect } from "react";
import { Report } from "../types/@types";
import {
  postReports,
  deleteEntireFile,
  deleteReports,
  getUploadDates,
  getReportsByUploadDate,
  getReports,
  deleteMultipleReports,
  getReport,
  getLatestReports,
  getPopularReports,
  getRelatedReports,
} from "../api/reports";

interface IReportContext {
  isLoading: boolean;
  reports: Report[];
  report: Report;
  latestReports: Report[];
  popularReports: Report[];
  uploadDates: Date[];
  createReport: (formData: FormData) => Promise<void>;
  deleteReport: (id: string) => Promise<void>;
  deleteFile: (fileId: string) => Promise<void>;
  deleteMultiple: (reportIds: number[]) => Promise<void>;
  fetchReport: (id: string) => Promise<void>;
  fetchLatestReports: () => Promise<void>;
  fetchPopularReports: () => Promise<void>;
  fetchRelatedReports: (id: string) => Promise<void>;
  fetchUploadDates: () => Promise<void>;
  fetchReportsByUploadDate: (uploadDate: Date) => Promise<void>;
}

interface ReportsProviderProps {
  children: React.ReactNode;
}

export const ReportsContext = createContext<IReportContext>(
  {} as IReportContext
);

export const ReportsProvider: React.FC<ReportsProviderProps> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reports, setReports] = useState<Report[]>([]);
  const [report, setReport] = useState<Report>({} as Report);
  const [latestReports, setLatestReports] = useState<Report[]>([]);
  const [popularReports, setPopularReports] = useState<Report[]>([]);
  const [uploadDates, setUploadDates] = useState<Date[]>([]);

  useEffect(() => {
    fetchReports();
    fetchLatestReports();
    fetchPopularReports();
    getUploadDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReports = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getReports();
      setReports(response.data);
    } catch (error) {
      console.error("Failed to fetch reports", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const createReport = useCallback(
    async (formData: FormData) => {
      setIsLoading(true);
      try {
        await postReports(formData);
      } catch (error) {
        console.error("Failed to create and fetch reports", error);
      } finally {
        setIsLoading(false);
      }
    },

    []
  );

  const deleteReport = useCallback(async (id: string) => {
    setIsLoading(true);
    await deleteReports(id);
    setIsLoading(false);
  }, []);

  const deleteFile = useCallback(async (fileId: string) => {
    setIsLoading(true);
    await deleteEntireFile(fileId);
    setIsLoading(false);
  }, []);

  const deleteMultiple = useCallback(async (reportIds: number[]) => {
    setIsLoading(true);
    await deleteMultipleReports(reportIds);
    setIsLoading(false);
  }, []);

  const fetchReport = useCallback(async (id: string) => {
    setIsLoading(true);
    const response = await getReport(id);
    setReport(response.data);
    setIsLoading(false);
  }, []);

  const fetchLatestReports = useCallback(async () => {
    setIsLoading(true);
    const response = await getLatestReports();
    setLatestReports(response.data);
    setIsLoading(false);
  }, []);

  const fetchPopularReports = useCallback(async () => {
    setIsLoading(true);
    const response = await getPopularReports();
    setPopularReports(response.data);
    setIsLoading(false);
  }, []);

  const fetchRelatedReports = useCallback(async (id: string) => {
    setIsLoading(true);
    const response = await getRelatedReports(id);
    setReports(response.data);
    setIsLoading(false);
  }, []);

  const fetchUploadDates = useCallback(async () => {
    setIsLoading(true);
    const response = await getUploadDates();
    setUploadDates(response.data);
    setIsLoading(false);
  }, []);

  const fetchReportsByUploadDate = useCallback(async (uploadDate: Date) => {
    setIsLoading(true);
    const response = await getReportsByUploadDate(uploadDate);
    setReports(response.data);
    setIsLoading(false);
  }, []);

  return (
    <ReportsContext.Provider
      value={{
        isLoading,
        reports,
        report,
        latestReports,
        popularReports,
        uploadDates,
        createReport,
        deleteReport,
        deleteFile,
        deleteMultiple,
        fetchReport,
        fetchLatestReports,
        fetchPopularReports,
        fetchRelatedReports,
        fetchUploadDates,
        fetchReportsByUploadDate,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};
