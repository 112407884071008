import React, { useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader";
import PageHeader from "../../components/PageHeader";
import { PublisherContext } from "../../contexts/PublisherContext";
import "./publishers.css";
import { formatString } from "../../utils/formatString";
import SEO from "../../seo/SEO";
import { Link } from "react-router-dom";

interface PublisherCardProps {
  publisher: string;
  count: number;
}

const PublisherCard = ({ publisher, count }: PublisherCardProps) => {
  return (
    <Card className="publisher-card-container mb-1 mx-auto d-flex flex-row">
      <div
        style={{
          flexGrow: 1,
          fontSize: "1.2em",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        {publisher}
      </div>
      <div
        style={{
          fontSize: "1em",
          color: "#999",
        }}
      >
        {count} reports
      </div>
    </Card>
  );
};

const Publishers = () => {
  const { publishers, isLoading } = useContext(PublisherContext);

  return (
    <>
      <SEO
        title="Publishers - Right Sight Research"
        description="Browse market research reports from various publishers."
        canonicalUrl="https://www.rightsightresearch.com/publishers"
      />

      <Loader isLoading={isLoading} />
      <PageHeader title="Publishers" />
      <Row className="d-flex justify-content-center mx-auto mb-3 publisher-cards-container">
        {publishers.map((publisher, index) => (
          <Col xs={12} md={6} lg={6} key={publisher.publisher}>
            <Link to={`/publisher/${formatString(publisher.publisher)}`}>
              <PublisherCard
                key={Math.random()}
                publisher={publisher.publisher}
                count={publisher.count}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Publishers;
