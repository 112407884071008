import React from 'react'

interface TableOfContentProps {
  content: string;
}

const TableOfContent = (props: TableOfContentProps) => {
  const {content} = props;
  return (
    <pre>{content}</pre>
  )
}

export default TableOfContent