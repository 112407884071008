import React, { useState, FormEvent } from "react";
import "./searchbar.css";
import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ErrorWithResponse } from "../../types/@types";
import { useNavigate } from "react-router-dom";
import { formatString } from "../../utils/formatString";

interface SearchBarProps {
  headingVisible?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      navigate(`/search/${formatString(searchTerm)}`);
    } catch (error) {
      const typedError = error as ErrorWithResponse;
      if (typedError.response) {
        toast.error(typedError.response.data.message);
      }
      console.error(error);
    } finally {
      setIsLoading(false);
      setSearchTerm("");
    }
  };

  return (
    <div className="homepage-search-root-wrapper">
      <div className="homepage-search-root">
        <Loader isLoading={isLoading} />
        <div>
          <div id="search-bar-container" className="clearfix center">
            <div className="clearfix">
              <form onSubmit={handleSubmit}>
                <input
                  required
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-box"
                  placeholder="Search market reports by keyword"
                />
                <button type="submit" className="search-button searchsubmit">
                  <FontAwesomeIcon icon={faSearch} size="2x" color="white" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
