import React, { useState, useEffect } from "react";
import { getRelatedReports } from "../../../api/reports";
import Loader from "../../Loader";
import { Report } from "../../../types/@types";
import "./related-reports.css";
import { formatUrlString } from "../../../utils/formatUrlString";
import { formatTitle } from "../../../utils/formatTitle";
import { Link } from "react-router-dom";

interface RelatedReportsProps {
  reportId: string;
}

const RelatedReports = (props: RelatedReportsProps) => {
  const { reportId } = props;

  const [reports, setReports] = useState<Report[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const reports = await getRelatedReports(reportId);
        setReports(reports.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [reportId]);

  return (
    <div className="related-reports-root">
      <Loader isLoading={isLoading} />
      <h3 className="h3-heading">
        Related Reports<span></span>
      </h3>
      <div className="related-reports-container">
        <ul>
          {reports &&
            reports.map((report) => (
              <li key={report._id}>
                <Link
                  to={`/reports/${formatUrlString(report.title)}/${
                    report.customId
                  }`}
                >
                  {formatTitle(report.title)}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default RelatedReports;
