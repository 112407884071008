import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const getBlogsData = async () => {
  return await axios.get(`${BASE_URL}/api/blogs`);
};

export const getBlog = async (id: string) => {
  return await axios.get(`${BASE_URL}/api/blogs/${id}`);
};

export const postBlog = async (data: any) => {
  return await axios.post(`${BASE_URL}/api/blogs`, data);
};

export const deleteBlog = async (id: number) => {
  return await axios.delete(`${BASE_URL}/api/blogs/${id}`);
};

export const updateBlog = async (id: number, data: any) => {
  return await axios.put(`${BASE_URL}/api/blogs/${id}`, data);
};
