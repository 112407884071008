import React, { useEffect, useState } from "react";
import {
  getCategoryReports,
  getPublisherReports,
  getReports,
  getSearchReports,
} from "../../api/reports";
import "bootstrap/dist/css/bootstrap.min.css";
import "./cards.css";
import { formatDate } from "../../utils/formatDate";
import { formatUrlString } from "../../utils/formatUrlString";
import Loader from "../Loader";
import { Report } from "../../types/@types";
import { formatTitle } from "../../utils/formatTitle";
import { formatDescr } from "../../utils/formatDescr";
import CategoryIcon from "../CategoryIcon/CategoryIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faMoneyBills,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface CardsProps {
  apiType: string;
  apiParam: string;
  setTotalItems: (count: number) => void;
  currentPage: number;
  priceFilter: {
    startPrice: number;
    endPrice: number;
  };
  publishedDateFilter: string;
  categoryFilter: string[];
  publisherFilter: string[];
}

const Cards = (props: CardsProps) => {
  const {
    apiType,
    apiParam,
    setTotalItems,
    currentPage,
    priceFilter,
    publishedDateFilter,
    categoryFilter,
    publisherFilter,
  } = props;
  const [reports, setReports] = useState<Report[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setReports([]);
    setTotalItems(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiType, apiParam]);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        let res;
        const filter = {
          priceFilter,
          publishedDateFilter,
          categoryFilter,
          publisherFilter,
        };
        setIsLoading(true);
        switch (apiType) {
          case "category":
            res = await getCategoryReports(apiParam || "", currentPage, filter);
            break;
          case "publisher":
            res = await getPublisherReports(
              apiParam || "",
              currentPage,
              filter
            );
            break;
          case "query":
            res = await getSearchReports(apiParam || "", currentPage, filter);
            break;
          case "all-reports":
            res = await getReports(currentPage, filter);
        }
        setIsLoading(false);
        setReports(res?.data.data);
        setTotalItems(res?.data.totalItems);
      } catch (err) {
        setReports([]);
        setTotalItems(0);
      } finally {
        setIsLoading(false);
      }
    };
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiType,
    apiParam,
    currentPage,
    priceFilter,
    publishedDateFilter,
    categoryFilter,
    publisherFilter,
  ]);

  if (!reports) return <Loader isLoading={true} />;

  return (
    <div className="mt-3 pb-5">
      <Loader isLoading={isLoading} />
      <div className="reports-list-container">
        {reports.map((report: Report) => (
          <div key={report._id} className="reports-list-card-root">
            <Link
              className="reports-list-card-top-root"
              to={`/reports/${formatUrlString(report.title)}/${
                report.customId
              }`}
            >
              <div className="reports-list-card-caregory-chip">
                <CategoryIcon
                  categoryName={report.category}
                  style={{ color: "#04525F" }}
                />
                <div>{report.category}</div>
              </div>
              <div className="reports-list-card-title">
                {formatTitle(report.title)}
              </div>
              <div className="reports-list-card-descr">
                {formatDescr(report.description)}
              </div>
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex align-items-center gap-2">
                  <FontAwesomeIcon
                    icon={faMoneyBills}
                    color="#6A3DED"
                    style={{
                      borderRadius: 40,
                      background: "#F3EFFE",
                      padding: 8,
                    }}
                    width={16}
                    height={16}
                  />
                  <div>${report.oneUserPDF}</div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    color="#028D5E"
                    style={{
                      borderRadius: 40,
                      background: "#E6F6F1",
                      padding: 8,
                    }}
                    width={16}
                    height={16}
                  />
                  <div>{formatDate(report.publishDate)}</div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2">
                <FontAwesomeIcon
                  icon={faUser}
                  color="#2BADF8"
                  style={{
                    borderRadius: 40,
                    background: "#EBF2FF",
                    padding: 8,
                  }}
                  width={16}
                  height={16}
                />
                <div>{report.publisher}</div>
              </div>
            </Link>
            <div className="d-flex">
              <Link
                to={`/reports/${formatUrlString(report.title)}/${
                  report.customId
                }#sample`}
                className="reports-list-card-free-sample-btn"
              >
                Free Sample
              </Link>
              <Link
                className="reports-list-card-buy-now-btn"
                to={`/checkout?report_id=${report.customId}&currency=USD&license=1-User_License`}
              >
                Buy Now
              </Link>
            </div>
          </div>
        ))}
      </div>
      {!reports.length && !isLoading && (
        <div className="h3 text-center mt-5">No reports found.</div>
      )}
    </div>
  );
};

export default Cards;
