import React from "react";
import "./page-header.css";

interface PageHeaderProps {
  title: string;
}

const PageHeader = (props: PageHeaderProps) => {
  const { title } = props;
  return <div className="page-header-title">{title}</div>;
};

export default PageHeader;
