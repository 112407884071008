import React from "react";
import { Navigate, useLocation } from "react-router-dom";

interface ProtectedRoutesProps {
  children: JSX.Element;
}

const ProtectedRoutes = (props: ProtectedRoutesProps) => {
  const { children } = props;
  const location = useLocation();
  const token = localStorage.getItem("token");

  return token ? (
    children
  ) : (
    <Navigate to={`/admin/login`} state={{ from: location }} />
  );
};

export default ProtectedRoutes;
