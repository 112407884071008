import React, { useState } from "react";
import "./become-publisher.css";
import PageHeader from "../../components/PageHeader";
import SEO from "../../seo/SEO";
import { countries } from "../../utils/countries";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { postMessage } from "../../api/messages";

const BecomePublisher = () => {
  const [title, setTitle] = useState("Mr.");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const validateForm = () => {
      if (!name) return true;
      if (!email) return true;
      if (!organization) return true;
      if (!jobTitle) return true;
      if (!address) return true;
      if (!city) return true;
      if (!state) return true;
      if (!zipCode) return true;
      if (!country) return true;
      if (!phoneNumber) return true;
      if (!websiteUrl) return true;
    };

    if (validateForm())
      return toast.error("Please fill all the required fields.");

    const messageData = {
      title,
      name,
      email,
      organization,
      jobTitle,
      address,
      city,
      state,
      zipCode,
      country,
      phoneNumber,
      messageType: "become-publisher",
      linkToReport: websiteUrl,
    };

    setIsLoading(true);

    try {
      await postMessage(messageData);
      setTitle("");
      setName("");
      setEmail("");
      setOrganization("");
      setJobTitle("");
      setAddress("");
      setCity("");
      setState("");
      setZipCode("");
      setCountry("");
      setPhoneNumber("");
      setWebsiteUrl("");
      toast.success("Message sent successfully!");
    } catch (error) {
      toast.error("Failed to send message:" + error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="become-publisher-container">
      <Loader isLoading={isLoading} />
      <SEO
        title="Become a Publisher - Right Sight Research"
        description="Become a publisher and reach a wider audience with Right Sight Research."
        canonicalUrl="https://www.rightsightresearch.com/become-publisher"
      />

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <PageHeader title="Become A Publisher" />

        <form
          onSubmit={handleSubmit}
          className="form-horizontal"
          id="publisher_enquiry_form"
        >
          <div className="become-publisher-form-root">
            <div className="become-publisher-form">
              <div className="form-group">
                <label>Title </label>
                <select
                  id="edit-submitted-title"
                  name="publisher_enquiry[title]"
                  className="form-control become-publisher-form-input"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                >
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Prof.">Prof.</option>
                </select>
              </div>
              <div className="form-group">
                <label>
                  Name <span>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="edit-submitted-name"
                  name="publisher_enquiry[name]"
                  className="form-control required become-publisher-form-input"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="form-group">
                <label>
                  Email <span>*</span>
                </label>
                <br />
                <input
                  className="form-control required become-publisher-form-input"
                  type="email"
                  id="edit-submitted-email"
                  name="publisher_enquiry[email]"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="form-group">
                <label>
                  Organization <span>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="edit-submitted-organization"
                  name="publisher_enquiry[organization]"
                  className="form-control required become-publisher-form-input"
                  onChange={(e) => setOrganization(e.target.value)}
                  value={organization}
                />
              </div>
              <div className="form-group">
                <label>
                  Job Title <span>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="edit-submitted-job-title"
                  name="publisher_enquiry[job_title]"
                  className="form-control required become-publisher-form-input"
                  onChange={(e) => setJobTitle(e.target.value)}
                  value={jobTitle}
                />
              </div>
              <div className="form-group">
                <label>
                  Address <span>*</span>
                </label>
                <br />
                <textarea
                  id="edit-submitted-address"
                  name="publisher_enquiry[address]"
                  className="form-control required become-publisher-form-input"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                ></textarea>
              </div>
              <div className="form-group">
                <label>
                  City <span>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="edit-submitted-city"
                  name="publisher_enquiry[city]"
                  className="form-control required become-publisher-form-input"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
              </div>
              <div className="form-group">
                <label>
                  State/Province/Region <span>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="edit-submitted-state-province-region"
                  name="publisher_enquiry[state]"
                  className="form-control required become-publisher-form-input"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                />
              </div>
              <div className="form-group">
                <label>
                  Zip/Postal Code <span>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="edit-submitted-zip-postal-code"
                  name="publisher_enquiry[postal_code]"
                  className="form-control required become-publisher-form-input"
                  onChange={(e) => setZipCode(e.target.value)}
                  value={zipCode}
                />
              </div>
              <div className="form-group">
                <label>
                  Country <span>*</span>
                </label>
                <br />
                <select
                  className="form-control become-publisher-form-input"
                  id="publisher_enquiry_country"
                  name="publisher_enquiry[country]"
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                >
                  <option value="">--select country--</option>
                  {countries.map((country) => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>
                  Phone Number <span>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="edit-submitted-phone-number"
                  name="publisher_enquiry[phone_no]"
                  className="form-control required become-publisher-form-input"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                />
              </div>
              <div className="form-group ">
                <label>
                  Web Site URL <span>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="edit-submitted-web-site-url"
                  name="publisher_enquiry[website_url]"
                  className="form-control required className= become-publisher-form-input"
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                  value={websiteUrl}
                />
              </div>

              <div className="form-actions">
                <button
                  className="button-primary btn btn-success submit-btn"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BecomePublisher;
