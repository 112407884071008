import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "../pages/Homepage/Homepage";
import ReportDetail from "../pages/ReportDetail";
import AboutUs from "../pages/AboutUs/AboutUs";
import ContactUs from "../pages/ContactUs/ContactUs";
import OrderProcess from "../pages/OrderProcess/OrderProcess";
import Faqs from "../pages/Faqs/Faqs";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import Checkout from "../pages/Checkout";
import Categories from "../pages/Categories";
import BlogList from "../pages/BlogList";
import Blog from "../pages/Blog";
import ReportsList from "../pages/ReportsList";
import Publishers from "../pages/Publishers";
import BecomePublisher from "../pages/BecomePublisher/BecomePublisher";
import PrivateRoute from "./PrivateRoute";
import AdminDashboard from "../pages/AdminDashboard";
import AdminLogin from "../pages/AdminDashboard/AdminLogin";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import SyndicatedReport from "../pages/OurServices/SyndicatedReports/SyndicatedReport";
import ConsultingServices from "../pages/OurServices/ConsultingServices/ConsultingServices";
import CustomizedResearch from "../pages/OurServices/CustomizedResearch/CustomizedResearch";
import ThankYou from "../pages/ThankYou/ThankYou";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/order-process" element={<OrderProcess />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/reports/:reportTitle/:id" element={<ReportDetail />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/blogs" element={<BlogList />} />
      <Route path="/blog/:id" element={<Blog />} />
      <Route path="/category/:category" element={<ReportsList />} />
      <Route path="/publisher/:publisher" element={<ReportsList />} />
      <Route path="/search/:query" element={<ReportsList />} />
      <Route path="/publishers" element={<Publishers />} />
      <Route path="/all-reports" element={<ReportsList />} />
      <Route path="/become-publisher" element={<BecomePublisher />} />
      <Route path="/syndicated-reports" element={<SyndicatedReport />} />
      <Route path="/consulting-services" element={<ConsultingServices />} />
      <Route path="/customized-research" element={<CustomizedResearch />} />
      <Route path="/thankyou/:type/:id" element={<ThankYou />} />

      <Route
        path="/admin"
        element={
          <PrivateRoute>
            <AdminDashboard />
          </PrivateRoute>
        }
      />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
