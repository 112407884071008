import React, { useState, useEffect } from "react";
import Filter from "../../components/ReportsList/Filter";
import Cards from "../../components/ReportsList/Cards";
import { useParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import PageHeader from "../../components/PageHeader";
import { reformatString } from "../../utils/reformatString";
import SEO from "../../seo/SEO";

const ReportsList = () => {
  const params = useParams();

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [priceFilter, setPriceFilter] = useState<{
    startPrice: number;
    endPrice: number;
  }>({
    startPrice: 0,
    endPrice: Infinity,
  });
  const [publishedDateFilter, setPublishedDateFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const [publisherFilter, setPublisherFilter] = useState<string[]>([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const setTotalItems = (count: number) => {
    setTotalCount(count);
  };

  return (
    <>
      {params.category ? (
        <SEO
          title={`${reformatString(
            Object.values(params)[0] || ""
          )} Market Research Reports: Industry Size, Share, and Growth`}
          description={`Explore market trends and forecasts in the ${
            Object.values(params)[0]
          } market. These reports include a comprehensive analysis of market size, share, and growth.`}
          canonicalUrl={`https://www.rightsightresearch.com/${
            Object.keys(params)[0]
          }/${Object.values(params)[0]}`}
        />
      ) : (
        <SEO
          title="Reports - Right Sight Research"
          description="Explore our extensive list of market research reports."
          canonicalUrl={`https://www.rightsightresearch.com/${
            Object.keys(params)[0] === "query"
              ? "search"
              : Object.keys(params)[0]
          }/${Object.values(params)[0]}`}
        />
      )}

      <PageHeader
        title={
          reformatString(Object.values(params)[0] || "all-reports") +
          " Market Research Report"
        }
      />
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <Filter
            apiType={Object.keys(params)[0] || "all-reports"}
            priceFilter={priceFilter}
            setPriceFilter={setPriceFilter}
            publishedDateFilter={publishedDateFilter}
            setPublishedDateFilter={setPublishedDateFilter}
            categoryFilter={categoryFilter}
            setCategoryFilter={setCategoryFilter}
            publisherFilter={publisherFilter}
            setPublisherFilter={setPublisherFilter}
          />
        </div>
        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
          <Cards
            apiType={Object.keys(params)[0] || "all-reports"}
            apiParam={Object.values(params)[0] || ""}
            setTotalItems={setTotalItems}
            currentPage={currentPage}
            priceFilter={priceFilter}
            publishedDateFilter={publishedDateFilter}
            categoryFilter={categoryFilter}
            publisherFilter={publisherFilter}
          />
          {totalCount > 0 && (
            <Pagination
              totalItems={totalCount}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ReportsList;
