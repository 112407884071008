import React, { useState } from "react";
import "./purchase-report.css";
import { useNavigate } from "react-router-dom";
import {
  USD_TO_EUR,
  USD_TO_GBP,
  USD_TO_INR,
  USD_TO_JPY,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";

interface PurchaseReportProps {
  oneUserPDF: number | null;
  fiveUserPDF: number | null;
  sitePDF: number | null;
  enterprisePDF: number | null;
  id: string;
}

interface CurrencyTabProps {
  currency: string;
  symbol: string;
  rates: (number | null)[];
  active?: boolean;
  setSelectedLicense: React.Dispatch<React.SetStateAction<string>>;
}

const CurrencyTab: React.FC<CurrencyTabProps> = ({
  currency,
  symbol,
  rates,
  active = false,
  setSelectedLicense,
}) => {
  const licenses = [
    "1-User License",
    "5-User License",
    "Site PDF",
    "Enterprise License",
  ];

  return (
    <div
      role="tabpanel"
      className={`tab-pane ${active ? "active" : ""}`}
      id={currency}
    >
      <table className="table ">
        <tbody>
          {licenses.map((license, i) => {
            if (rates[i] !== null && rates[i] !== 0)
              return (
                <tr key={license} className="checkout-label-tr">
                  <td>
                    <div className="radio">
                      <label>
                        <input
                          id={`currency_${license.replace(
                            /\s/g,
                            "_"
                          )}-${currency}`}
                          name="currency"
                          type="radio"
                          className="checkout-label"
                          value={`${license.replace(/\s/g, "_")}-${currency}`}
                          onClick={() =>
                            setSelectedLicense(license.replace(/\s/g, "_"))
                          }
                        />
                        {license}
                      </label>
                    </div>
                  </td>
                  <td>
                    <label>
                      {symbol}
                      {rates[i]?.toFixed(2)}
                    </label>
                  </td>
                </tr>
              );
            else return null;
          })}
        </tbody>
      </table>
    </div>
  );
};

const PurchaseReport: React.FC<PurchaseReportProps> = ({
  oneUserPDF,
  fiveUserPDF,
  sitePDF,
  enterprisePDF,
  id,
}) => {
  const navigate = useNavigate();

  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [selectedLicense, setSelectedLicense] = useState("");

  const currencyTabs = [
    {
      currency: "USD",
      symbol: "$",
      rates: [oneUserPDF, fiveUserPDF, sitePDF, enterprisePDF],
    },
    {
      currency: "GBP",
      symbol: "£",
      rates: [
        (oneUserPDF ? oneUserPDF : 0) * USD_TO_GBP,
        (fiveUserPDF ? fiveUserPDF : 0) * USD_TO_GBP,
        (sitePDF ? sitePDF : 0) * USD_TO_GBP,
        (enterprisePDF ? enterprisePDF : 0) * USD_TO_GBP,
      ],
    },
    {
      currency: "EUR",
      symbol: "€",
      rates: [
        (oneUserPDF ? oneUserPDF : 0) * USD_TO_EUR,
        (fiveUserPDF ? fiveUserPDF : 0) * USD_TO_EUR,
        (sitePDF ? sitePDF : 0) * USD_TO_EUR,
        (enterprisePDF ? enterprisePDF : 0) * USD_TO_EUR,
      ],
    },
    {
      currency: "JPY",
      symbol: "¥",
      rates: [
        (oneUserPDF ? oneUserPDF : 0) * USD_TO_JPY,
        (fiveUserPDF ? fiveUserPDF : 0) * USD_TO_JPY,
        (sitePDF ? sitePDF : 0) * USD_TO_JPY,
        (enterprisePDF ? enterprisePDF : 0) * USD_TO_JPY,
      ],
    },
    {
      currency: "INR",
      symbol: "₹",
      rates: [
        (oneUserPDF ? oneUserPDF : 0) * USD_TO_INR,
        (fiveUserPDF ? fiveUserPDF : 0) * USD_TO_INR,
        (sitePDF ? sitePDF : 0) * USD_TO_INR,
        (enterprisePDF ? enterprisePDF : 0) * USD_TO_INR,
      ],
    },
  ];

  return (
    <div className="purchase-report-root">
      <div className="top-bar">
        <h3 className="h3-heading">Checkout</h3>

        <select
          value={selectedCurrency}
          onChange={(e) => setSelectedCurrency(e.target.value)}
          className="form-select"
        >
          {currencyTabs.map(({ currency, symbol }) => (
            <option value={currency} key={currency}>
              {symbol} {currency}
            </option>
          ))}
        </select>
      </div>

      <div className="tab-content">
        {currencyTabs.map((props) => (
          <CurrencyTab
            {...props}
            active={
              props.currency.toLowerCase() === selectedCurrency.toLowerCase()
            }
            setSelectedLicense={setSelectedLicense}
            key={props.currency}
          />
        ))}
      </div>
      <div className="purchase-btn-div">
        <button
          className="purchase-buttons"
          id="send"
          type="submit"
          onClick={() => {
            if (selectedLicense) {
              navigate(
                `/checkout?report_id=${id}&currency=${selectedCurrency}&license=${selectedLicense}`
              );
            } else {
              toast.error(
                "Please select a license type before proceeding to checkout."
              );
            }
          }}
        >
          <FontAwesomeIcon icon={faMoneyBill} size="lg" color="#fff" />
          Buy Now
        </button>
      </div>
    </div>
  );
};

export default PurchaseReport;
