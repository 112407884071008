import React from "react";
import PageHeader from "../../../components/PageHeader";
import "./consulting-services.css";
import SEO from "../../../seo/SEO";

const ConsultingServices = () => {
  return (
    <div className="consulting-services-container margin-horizontal-consulting-services-page">
      <SEO
        title="Market Research Consulting Services | Right Sight Research"
        description="Maximize success with our market research consulting services. Expert insights, tailored strategies, and data-driven solutions for business growth. Explore now!"
        canonicalUrl="https://www.rightsightresearch.com/consulting-services"
      />
      <PageHeader title="Consulting Services" />
      <p className="consulting-services-p">
        Our consultation support focuses on our client’s difficulties and
        opportunities such as operations, strategy, marketing, technology,
        analytics, finance, mergers and benefits, and sustainability in overall
        industries and geographies.
      </p>{" "}
      <p className="consulting-services-p">
        We possess deep, functional expertise but are known for our holistic
        view: we capture data across borders and between the silos of any
        business. We have the best outcome from optimizing the total, not just
        the initial pieces of data.
      </p>
      <p className="consulting-services-p">
        <p className="consulting-services-p1">
          Our consulting services make you:
        </p>

        <ul>
          <li className="consulting-services-p">
            <strong>Agile:</strong> Become faster, more flexible, and intensely
            customer-focused.
          </li>
          <li className="consulting-services-p">
            <strong>Growth Hacker:</strong> Align values with policy and
            concentrate on growth.
          </li>
          <li className="consulting-services-p">
            <strong> Customer Satisfaction:</strong>
            create great consumer experiences that build loyalty and reduce
            costs.
          </li>
          <li className="consulting-services-p">
            <strong> Gain Equity:</strong> We encourage investors beyond the
            entire venture life cycle.
          </li>
          <li className="consulting-services-p">
            <strong> Procurement expert:</strong> Decrease cost base. Promote
            gains. Repeat.
          </li>
          <li className="consulting-services-p">
            <strong>Strategic:</strong> have a strategy ready for any upcoming
            challenge.
          </li>
          <li className="consulting-services-p">
            <strong>Sustainable:</strong> Set principles that match a source of
            continuous and growing value.
          </li>
          <li className="consulting-services-p">
            <strong>Transform:</strong> Adjust the trajectory of your company
            and deliver excellent results.
          </li>
        </ul>
      </p>
    </div>
  );
};

export default ConsultingServices;
