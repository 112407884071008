import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SearchBar from "./components/SearchBar";
import AppRoutes from "./routes/Routes";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import CategoriesHeader from "./components/CategoriesHeader";
import BreadCrumbWrapper from "./components/BreadCrumb";
import SubFooter from "./components/SubFooter/SubFooter";
//@ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import useIsMobile from "./hooks/useIsMobile";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");
  const isBlogsRoute = location.pathname.startsWith("/blogs");
  const isBlogRoute = location.pathname.startsWith("/blog/");
  const showSearchBar = !(isAdminRoute || isBlogsRoute || isBlogRoute);
  const isMobile = useIsMobile();
  return (
    <div>
      <ScrollToTop />
      <div className="App">
        {!isAdminRoute && <Header />}
        {showSearchBar && <SearchBar />}
        {showSearchBar && <CategoriesHeader />}
        {!isAdminRoute && !isMobile && (
          <BreadCrumbWrapper path={location.pathname} />
        )}
        <AppRoutes />
        {!isAdminRoute && <Footer />}
        {!isAdminRoute && <SubFooter />}
        <ToastContainer />
        {!isAdminRoute && (
          <TawkMessengerReact
            propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID}
            widgetId={process.env.REACT_APP_TAWIK_WIDGET_ID}
          />
        )}
      </div>
    </div>
  );
}

export default App;
