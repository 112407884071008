type Feature = {
  icon: string;
  title: string;
  description: string;
};

export const features: Feature[] = [
  {
    icon: "fa-chart-line",
    title: "Growth Driven Research",
    description:
      "We stay on top of industry trends to help companies become more sustainable over time. Our insights lead to real, ongoing progress for businesses.",
  },
  {
    icon: "fa-database",
    title: "Evolving Stability",
    description:
      "Our reports cover a wide range of topics, and we always make sure the information is current and based on the latest industry knowledge.",
  },
  {
    icon: "fa-globe",
    title: "Worldwide Reach",
    description:
      "We gather info from all corners of the globe, then share it back out with everyone. We are worldwide.",
  },
  {
    icon: "fa-chess-king",
    title: "Market Intelligence Powerhouse",
    description:
      "Our team of industry experts has created a high-quality report that will give you the information you need.",
  },
];