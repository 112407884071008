import axios from "axios";
import { Filter } from "../types/@types";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const getReports = async (page = 1, filter?: Filter) => {
  return await axios.get(`${BASE_URL}/api/reports`, { params: { page, filter } });
};

export const getReport = async (id: string) => {
  return await axios.get(`${BASE_URL}/api/reports/${id}`);
};

export const getUploadDates = async () => {
  return await axios.get(`${BASE_URL}/api/reports/upload-dates`);
};

export const getReportsByUploadDate = async (uploadDate: Date, page = 1) => {
  return await axios.get(`${BASE_URL}/api/reports/upload-date/${uploadDate}`, { params: { page } });
};


export const getCategories = async () => {
  return await axios.get(`${BASE_URL}/api/reports/categories`);
};

export const getPublishers = async () => {
  return await axios.get(`${BASE_URL}/api/reports/publishers`);
};

export const getPopularReports = async () => {
  return await axios.get(`${BASE_URL}/api/reports/popular`);
};

export const getLatestReports = async () => {
  return await axios.get(`${BASE_URL}/api/reports/latest`);
};

export const getRelatedReports = async (id: string) => {
  return await axios.get(`${BASE_URL}/api/reports/related/${id}`);
};

export const getCategoryReports = async (category: string, page = 1, filter: Filter) => {
  return await axios.get(`${BASE_URL}/api/reports/category/${category}`, { params: { page, filter } });
};

export const getPublisherReports = async (publisher: string, page = 1, filter: Filter) => {
  return await axios.get(`${BASE_URL}/api/reports/publisher/${publisher}`, { params: { page, filter } });
};

export const getSearchReports = async (query: string, page = 1, filter: Filter) => {
  return await axios.get(`${BASE_URL}/api/reports/search/${query}`, { params: { page, filter } });
};

export const postReports = async (formData: FormData) => {
  return await axios.post(`${BASE_URL}/api/reports`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteReports = async (id: string) => {
  return await axios.delete(`${BASE_URL}/api/reports/${id}`);
};

export const deleteEntireFile = async (fileId: string) => {
  return await axios.delete(`${BASE_URL}/api/reports/delete-file/${fileId}`);
};

export const deleteMultipleReports = async (reportIds: number[]) => {
  return await axios.delete(`${BASE_URL}/api/reports/delete-multiple`, {
    data: { reportIds }
  });
};
