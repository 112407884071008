import React, { useContext } from "react";
import "./popular-reports.css";
import CarouselWrapper from "../../CarouselWrapper";
import { Report } from "../../../types/@types";
import Loader from "../../Loader";
import { formatUrlString } from "../../../utils/formatUrlString";
import { ReportsContext } from "../../../contexts/ReportsContext";
import useIsMobile from "../../../hooks/useIsMobile";
import { formatTitle } from "../../../utils/formatTitle";
import { formatDescr } from "../../../utils/formatDescr";
import { Link } from "react-router-dom";

const PopularReports: React.FC = () => {
  const { popularReports, isLoading } = useContext(ReportsContext);
  const isMobile = useIsMobile();
  const CHUNK_SIZE = isMobile ? 1 : 4;

  const reportChunks = [];
  for (let i = 0; i < popularReports.length; i += CHUNK_SIZE) {
    reportChunks.push(popularReports.slice(i, i + CHUNK_SIZE));
  }

  return (
    <div className="popular-reports-root">
      <Loader isLoading={isLoading} />
      <h3>Popular Reports</h3>
      <div className="popular-reports-container">
        <CarouselWrapper interval={5000} controls={false} indicators={false}>
          {reportChunks.map((reports: Report[], index: number) => (
            <div className="popular-reports-card-container" key={Math.random()}>
              {reports.map((report: Report, index: number) => {
                const { title, description, customId } = report;
                return (
                  <Link
                    to={`/reports/${formatUrlString(
                      report.title
                    )}/${customId}`}
                    className="report-link"
                    key={customId}
                  >
                    <div
                      className={`popular-reports-card-root`}
                      key={Math.random()}
                    >
                      {formatTitle(title)}
                      <p className="report-description">
                        {formatDescr(description)}
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
          ))}
        </CarouselWrapper>
      </div>
    </div>
  );
};

export default PopularReports;
