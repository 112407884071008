import React from "react";
import "./footer.css";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row justify-content-center ">
          <img
            src={require("../../assets/rsr-logo-white.png")}
            alt="Right Sight Research"
            className="rsr-white-logo col-lg-3 col-md-3 col-sm-3 col-xs-12 mt-0"
          />
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
            <h2>Right Sight Research</h2>
            <ul>
              <li>
                <Link to="/about-us">
                  <FontAwesomeIcon icon={faAngleRight} size="xs" /> About Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
            <h2>Contact Us</h2>
            <ul>
              <li>
                <Link to="tel:+14154495492" className="footer-email">
                  <FontAwesomeIcon icon={faAngleRight} size="xs" /> Ph:
                  +1 415 449 5492 (US)
                </Link>
              </li>

              <li>
                <Link to="tel:+918591141509" className="footer-email">
                  <FontAwesomeIcon icon={faAngleRight} size="xs" /> Ph:
                  +91 85911 41509 (Ind)
                </Link>
              </li>

              <li>
                <Link
                  to="mailto:info@rightsightresearch.com"
                  className="footer-email"
                >
                  <FontAwesomeIcon icon={faAngleRight} size="xs" />{" "}
                  info@rightsightresearch.com
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
            <h2>Support</h2>
            <ul className="footer-support-list">
              <li>
                <Link to="/order-process">
                  <FontAwesomeIcon icon={faAngleRight} size="xs" /> Order
                  Process
                </Link>
              </li>
              <li>
                <Link to="/blogs">
                  <FontAwesomeIcon icon={faAngleRight} size="xs" /> Blog
                </Link>
              </li>
              <li>
                <Link to="/faqs">
                  <FontAwesomeIcon icon={faAngleRight} size="xs" /> FAQs
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">
                  <FontAwesomeIcon icon={faAngleRight} size="xs" /> Privacy
                  Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">
                  <FontAwesomeIcon icon={faAngleRight} size="xs" /> Terms &amp;
                  Conditions
                </Link>
              </li>
              <li>
                <a href="/sitemap.xml">
                  <FontAwesomeIcon icon={faAngleRight} size="xs" /> Site Map
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
