import React, { useState } from "react";
import CheckoutDetails from "../../components/Checkout/CheckoutDetails";
import CheckoutUserDetails from "../../components/Checkout/CheckoutUserDetails";
import CheckoutPaymentOptions from "../../components/Checkout/CheckoutPaymentOptions";
import SEO from "../../seo/SEO";

export type userInfoType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  companyName: string;
  country: string;
  comments: string;
  isCaptchaVerified: boolean;
  hasAcceptedTerms: boolean;
};

const Checkout = () => {
  const [userInfo, setUserInfo] = useState<userInfoType>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
    companyName: "",
    country: "",
    comments: "",
    isCaptchaVerified: false,
    hasAcceptedTerms: false,
  });
  console.log(userInfo);
  return (
    <div className="checkout-container margin-horizontal-checkout-page">
      <SEO
        title="Checkout - Right Sight Research"
        description="Secure and easy checkout for purchasing market research reports."
        canonicalUrl="https://www.rightsightresearch.com/checkout"
      />

      <div className="checkout-row">
        <CheckoutDetails />
      </div>
      <div className="row">
        <div className="col-md-7">
          <CheckoutUserDetails setUserInfo={setUserInfo} />
        </div>
        <div className="col-md-5">
          <CheckoutPaymentOptions />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
