import React from "react";
import "./thank-you.css";
import { Link, Navigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faSquareTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faRss } from "@fortawesome/free-solid-svg-icons";

const ThankYou = () => {
  const { type } = useParams<{type: string, id: string}>();
  const allowedTypes = ['request-sample-report', 'enquiry-before-buying', 'check-discount'];

  if (!type || !allowedTypes.includes(type)) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div className="thanks-root">
      <img
        src={require("../../assets/checkbox-image.png")}
        className="checkbox-image"
        alt="checkbox"
      />
      <h1 className="thank-you-text">THANK YOU</h1>
      <div className="description-text">
        We have received your request. Our research specialist will contact you
        soon.
      </div>
      <div>
        <div className="socials">
          <Link
            to="https://www.facebook.com/profile.php?id=61551581332537"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" color=" #055160" />
          </Link>
          <Link
            to="https://twitter.com/rightsightrsch"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <FontAwesomeIcon
              icon={faSquareTwitter}
              size="2x"
              color=" #055160"
            />
          </Link>
          <Link
            to="https://www.linkedin.com/company/right-sight-market-research-pvt-ltd"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" color=" #055160" />
          </Link>
          <Link
            to="https://facebook.com"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <FontAwesomeIcon icon={faRss} size="2x" color=" #055160" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
