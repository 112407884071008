import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { countries } from "../../../utils/countries";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import { postMessage } from "../../../api/messages";
import "../report-detail-form.css";
import useIsMobile from "../../../hooks/useIsMobile";
import { jsPDF } from "jspdf";
import { rsrLogoDataURL } from "../../../assets/rsr-logo-dataurl";
import { formatUrlString } from "../../../utils/formatUrlString";
import { useNavigate } from "react-router-dom";

interface EnquiryProps {
  link: string;
  id: string;
  title: string;
  description: string;
  tableOfContent: string;
  downloadSelected: boolean;
}

const Enquiry = (props: EnquiryProps) => {
  const router = useNavigate();
  const isMobile = useIsMobile();
  const { link, id, title, description, tableOfContent, downloadSelected } =
    props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    if (!firstName) return true;
    if (!lastName) return true;
    if (!email) return true;

    if (!jobTitle) return true;
    if (!company) return true;
    if (!phoneNumber) return true;

    if (!country) return true;
    if (!question) return true;
  };

  const handleDownload = () => {
    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    let yPosition = 10;

    pdf.setFontSize(14);

    const imgWidth = 30;
    const imgHeight = 40;
    const xPosition = (pageWidth - imgWidth) / 2;
    pdf.addImage(
      rsrLogoDataURL,
      "PNG",
      xPosition,
      yPosition,
      imgHeight,
      imgWidth
    );

    yPosition += imgWidth + 10;

    pdf.setFont("helvetica", "bold");
    let titleText = pdf.splitTextToSize(title, pageWidth - 40);
    pdf.text(titleText, 20, yPosition);
    yPosition += titleText.length * 5;

    const buttonX = 20;
    const buttonY = yPosition;
    const buttonWidth = 36;
    const buttonHeight = 9;
    const radius = 4;

    pdf.setFontSize(12);

    pdf.setDrawColor("#0b3c44");
    pdf.setFillColor("#0b3c44");
    pdf.roundedRect(
      buttonX,
      buttonY,
      buttonWidth,
      buttonHeight,
      radius,
      radius,
      "FD"
    );

    pdf.setTextColor("#fff");
    pdf.text("Go to report", buttonX + 5, buttonY + 6);
    pdf.link(buttonX, buttonY, buttonWidth, buttonHeight, {
      url: `https://rightsightresearch.com/reports/${formatUrlString(
        title
      )}/${id}`,
    });

    pdf.setTextColor(0);

    yPosition += buttonHeight + 10;

    if (yPosition > pageHeight - 20) {
      pdf.addPage();
      yPosition = 20;
    }

    pdf.setFontSize(14);
    pdf.text("Description", 20, yPosition);
    yPosition += 10;
    pdf.setFontSize(12);

    pdf.setFont("helvetica", "normal");
    let descriptionText = pdf.splitTextToSize(description, pageWidth - 40);
    for (let i = 0; i < descriptionText.length; i++) {
      if (yPosition > pageHeight - 20) {
        pdf.addPage();
        yPosition = 20;
      }
      pdf.text(descriptionText[i], 20, yPosition);
      yPosition += 7;
    }

    yPosition += 10;
    if (yPosition > pageHeight - 20) {
      pdf.addPage();
      yPosition = 20;
    }
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(14);
    pdf.text("Table of Content", 20, yPosition);
    yPosition += 10;
    pdf.setFontSize(12);

    pdf.setFont("helvetica", "normal");
    let tocText = pdf.splitTextToSize(tableOfContent, pageWidth - 40);
    for (let i = 0; i < tocText.length; i++) {
      if (yPosition > pageHeight - 20) {
        pdf.addPage();
        yPosition = 20;
      }
      pdf.text(tocText[i], 20, yPosition);
      yPosition += 7;
    }

    pdf.save(`${title}.pdf`);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (validateForm())
      return toast.error("Please fill all the required fields.");

    const messageData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      jobTitle,
      company,
      country,
      question,
      messageType: "enquiry",
      linkToReport: link,
    };

    setIsLoading(true);

    try {
      await postMessage(messageData);
      router(`/thankyou/enquiry-before-buying/${id}`);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setJobTitle("");
      setCompany("");
      setCountry("");
      setQuestion("");
      toast.success("Message sent successfully!");
      if (downloadSelected) handleDownload();
    } catch (error) {
      toast.error("Failed to send message:" + error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Loader isLoading={isLoading} />
      <div className="report-detail-form-form-container">
        <div className="report-detail-form-text-btn">
          <h5 className="report-detail-form-txt">
            Make an enquiry before buying this Report
          </h5>
          <Button
            className="align-self-end"
            href=""
            size="sm"
            variant="warning"
          >
            View Report
          </Button>
        </div>

        <div className="report-detail-form-form">
          <form onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Name:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      style={{ width: 200, marginRight: isMobile ? 0 : 5 }}
                      className="report-detail-form-input"
                      placeholder="First Name"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />

                    <input
                      style={{ width: isMobile ? 200 : 195 }}
                      className="report-detail-form-input"
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Email:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      className="report-detail-form-input"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Job Title:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      className="report-detail-form-input"
                      placeholder="Job Title"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Company:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      className="report-detail-form-input"
                      placeholder="Company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Phone No:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <input
                      className="report-detail-form-input"
                      placeholder="Phone No"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Country:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <select
                      className="report-detail-form-select-country"
                      id="country_id"
                      name="country_id"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">--select country--</option>
                      {countries.map((country) => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="report-detail-form-td">
                    <label className="report-detail-form-label">
                      Your Question:<span className="star">*</span>
                    </label>
                  </td>
                  <td className="report-detail-form-td">
                    <textarea
                      rows={4}
                      className="report-detail-form-textArea"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="report-detail-form-submit-btn">
              <Button variant="success" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
