import React, { useState, useEffect } from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import "./pagination.css";

interface IPaginationProps {
  totalItems: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination = (props: IPaginationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { totalItems, currentPage, onPageChange } = props;
  const [active, setActive] = useState(currentPage);
  const items = [];
  let numberOfPages = Math.ceil(totalItems / 12);
  const leftBoundary = 5;
  const rightBoundary = 2;

  const handlePageClick = (page: number) => {
    setActive(page);
    onPageChange(page);
    navigate(`${location.pathname}?page=${page}`);
  };

  for (let number = 1; number <= numberOfPages; number++) {
    if (
      number === 1 ||
      number === numberOfPages ||
      number === active ||
      (number < active && number > active - leftBoundary) ||
      (number > active && number < active + rightBoundary)
    ) {
      items.push(
        <BootstrapPagination.Item
          key={number}
          active={number === active}
          onClick={() => handlePageClick(number)}
        >
          {number}
        </BootstrapPagination.Item>
      );
    } else if (
      number === active - leftBoundary ||
      number === active + rightBoundary
    ) {
      items.push(<BootstrapPagination.Ellipsis key={Math.random()} />);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    if (page) {
      const pageNumber = parseInt(page, 10);
      setActive(pageNumber);
      onPageChange(pageNumber);
    }
  }, [location.search, onPageChange]);

  return (
    <div className="pagination-root">
      <BootstrapPagination className="custom-pagination">
        <BootstrapPagination.First
          onClick={() => handlePageClick(1)}
          disabled={active === 1}
        />
        <BootstrapPagination.Prev
          onClick={() => active > 1 && handlePageClick(active - 1)}
          disabled={active === 1}
        />
        {items}
        <BootstrapPagination.Next
          onClick={() => active < numberOfPages && handlePageClick(active + 1)}
          disabled={active === numberOfPages}
        />
        <BootstrapPagination.Last
          onClick={() => handlePageClick(numberOfPages)}
          disabled={active === numberOfPages}
        />
      </BootstrapPagination>
    </div>
  );
};

export default Pagination;
