import React, { useEffect, useRef, useContext } from "react";
import "./latest-reports.css";
import { Report } from "../../../types/@types";
import { formatDate } from "../../../utils/formatDate";
import Loader from "../../Loader";
import { formatUrlString } from "../../../utils/formatUrlString";
import { ReportsContext } from "../../../contexts/ReportsContext";
import CategoryIcon from "../../CategoryIcon/CategoryIcon";
import useIsMobile from "../../../hooks/useIsMobile";
import { Link } from "react-router-dom";

const LatestReports: React.FC = () => {
  const isMobile = useIsMobile();
  const { isLoading, latestReports } = useContext(ReportsContext);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container && latestReports.length > 0) {
      const totalHeight = container.scrollHeight;
      container.style.setProperty("--totalHeight", `${totalHeight}px`);

      const onAnimIteration = () => {
        container.style.setProperty("animation-play-state", "paused");
        container.style.setProperty("transform", "translateY(0)");
        setTimeout(() => {
          container.style.setProperty("animation-play-state", "running");
        }, 0);
      };

      container.addEventListener("animationiteration", onAnimIteration);

      return () => {
        container.removeEventListener("animationiteration", onAnimIteration);
      };
    }
  }, [latestReports]);

  const handleMouseEnter = () => {
    if (containerRef.current) {
      containerRef.current.classList.add("paused");
    }
  };

  const handleMouseLeave = () => {
    if (containerRef.current) {
      containerRef.current.classList.remove("paused");
    }
  };

  const reportItemElements = latestReports.map(
    (report: Report, index: number) => {
      const { publisher, title, publishDate, category } = report;
      return (
        <Link
          to={`/reports/${formatUrlString(report.title)}/${report.customId}`}
          className="report-item"
          key={Math.random()}
        >
          {!isMobile && (
            <CategoryIcon
              className="running-img img-thumbnail"
              categoryName={category}
              style={{ color: "#000" }}
            />
          )}
          <div className="report-details">
            <div className="report-title">{title}</div>
            <div className="report-publisher-date">
              <span className="publisher-date">
                <strong>Publisher: </strong>
              </span>
              <span className="publisher-text">{publisher}</span>
              <br />
              <span className="publisher-date">
                <strong>Published Date: </strong>
              </span>
              <span className="published-date-text">
                {formatDate(publishDate)}
              </span>
            </div>
          </div>
        </Link>
      );
    }
  );

  return (
    <div className="latest-reports-root">
      <Loader isLoading={isLoading} />
      <h4>Latest Reports</h4>
      <div className="reports-container">
        <div
          className={`reports-scroll`}
          ref={containerRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {reportItemElements}
        </div>
      </div>
    </div>
  );
};

export default LatestReports;
