import React from "react";
import "./homepage.css";
import PopularCategories from "../../components/Homepage/PopularCategories";
import LatestReports from "../../components/Homepage/LatestReports";
import PopularReports from "../../components/Homepage/PopularReports";
import Features from "../../components/Homepage/Features";
import ClientsCarousel from "../../components/Homepage/ClientsCarousel";
import CustomMarketResearch from "../../components/Homepage/CustomMarketResearch";
import Testimonials from "../../components/Homepage/Testimonials";
import PictureHeading from "../../components/Homepage/PictureHeading";
import useIsMobile from "../../hooks/useIsMobile";
import SEO from "../../seo/SEO";

const Homepage = () => {
  const isMobile = useIsMobile();
  return (
    <div>
      <SEO
        title="Right Sight Market Research - Market Research Reports and Industry Analysis"
        description="Right sight market research is one of the leading market research report provider, we provide wide range of market research reports. Get your industry insights now!"
        canonicalUrl="https://www.rightsightresearch.com/"
      />

      <PictureHeading />
      <div className="margin-horizontal-homepage">
        <div className="homepage-middle-section">
          <div style={{ flex: 1, padding: isMobile ? "" : "0 20px 0 0" }}>
            <PopularCategories />
          </div>
          <div style={{ flex: 2, padding: isMobile ? "" : "0 0 0 20px" }}>
            <LatestReports />
          </div>
        </div>
        <PopularReports />
      </div>
      <ClientsCarousel />
      <div className="margin-horizontal-homepage">
        <Features />
        <CustomMarketResearch />
        <Testimonials />
      </div>
    </div>
  );
};

export default Homepage;
