import React from "react";
import { ReportsProvider } from "./ReportsContext";
import { BlogProvider } from "./BlogContext";
import { CategoryProvider } from "./CategoryContext";
import { PublisherProvider } from "./PublisherContext";

interface ReportsProviderProps {
  children: React.ReactNode;
}

export const Providers: React.FC<ReportsProviderProps> = ({ children }) => {
  return (
    <CategoryProvider>
      <ReportsProvider>
        <BlogProvider>
          <PublisherProvider>{children}</PublisherProvider>
        </BlogProvider>
      </ReportsProvider>
    </CategoryProvider>
  );
};
