import React from "react";
import { Carousel } from "react-bootstrap";

interface CarouselWrapperProps {
  children: React.ReactNode;
  interval?: number;
}

const CarouselWrapper: React.FC<
  CarouselWrapperProps & React.ComponentProps<typeof Carousel>
> = ({ children, interval = 5000, ...props }) => {
  return (
    <Carousel {...props} interval={interval}>
      {React.Children.map(children, (child, index) => (
        <Carousel.Item key={Math.random()}>{child}</Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselWrapper;
