import React, { useContext, useState } from "react";
import "./filter.css";
import Form from "react-bootstrap/Form";
import { CategoryContext } from "../../contexts/CategoryContext";
import Loader from "../Loader";
import { PublisherContext } from "../../contexts/PublisherContext";
import useIsMobile from "../../hooks/useIsMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faChevronDown,
  faChevronUp,
  faFilter,
  faMoneyBill,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faRectangleList } from "@fortawesome/free-regular-svg-icons";
import { Button } from "react-bootstrap";

interface FilterProps {
  apiType: string;
  priceFilter: {
    startPrice: number;
    endPrice: number | null;
  };
  setPriceFilter: React.Dispatch<
    React.SetStateAction<{
      startPrice: number;
      endPrice: number;
    }>
  >;
  publishedDateFilter: string;
  setPublishedDateFilter: React.Dispatch<React.SetStateAction<string>>;
  categoryFilter: string[];
  setCategoryFilter: React.Dispatch<React.SetStateAction<string[]>>;
  publisherFilter: string[];
  setPublisherFilter: React.Dispatch<React.SetStateAction<string[]>>;
}

const priceFilterData = [
  { startPrice: 0, endPrice: 500 },
  { startPrice: 501, endPrice: 1000 },
  { startPrice: 1001, endPrice: 2000 },
  { startPrice: 2001, endPrice: 3000 },
  { startPrice: 3000, endPrice: null },
];

const publishedDateFilterData = [1, 3, 6, 12];

const TopFilter = (props: {
  filterData: any[];
  type: string;
  selectedFilter: any;
  setSelectedFilter: (filter: any) => void;
}) => {
  const { filterData, type, selectedFilter, setSelectedFilter } = props;

  const [isTopFilterOpen, setIsTopFilterOpen] = useState(false);

  const resetFilter = () => {
    if (type === "priceFilterData") {
      setSelectedFilter({ startPrice: 0, endPrice: Infinity });
    } else if (type === "publishedDateFilterData") {
      setSelectedFilter("");
    }
  };

  const handleFilterChange = (filter: any) => {
    setSelectedFilter(filter);
  };

  const isFilterReset =
    (type === "priceFilterData" &&
      selectedFilter.startPrice === 0 &&
      selectedFilter.endPrice === Infinity) ||
    (type === "publishedDateFilterData" && selectedFilter === "");

  return (
    <div>
      <Button
        variant="link"
        style={{ color: "inherit", textDecoration: 'none', width: '100%' }}
        onClick={() => setIsTopFilterOpen(!isTopFilterOpen)}
      >
        <div className="h3-heading">
          <div>
            <FontAwesomeIcon
              icon={type === "priceFilterData" ? faMoneyBill : faCalendarDays}
              style={{ marginRight: 12 }}
            />
            Sort by {type === "priceFilterData" ? "Price" : "Published Date"}
          </div>
          <FontAwesomeIcon
            icon={isTopFilterOpen ? faChevronUp : faChevronDown}
          />
        </div>
      </Button>
      {isTopFilterOpen && (
        <div className="radio-container">
          <Form>
            {filterData.map((data: any, index: number) => (
              <Form.Check
                key={Math.random()}
                className="filter-radio"
                type="radio"
                id={`${type}-${index}`}
                name={type}
                value={data}
                checked={!isFilterReset && selectedFilter === data}
                onChange={() => handleFilterChange(data)}
                label={
                  data.startPrice !== undefined
                    ? data.endPrice !== null
                      ? `USD ${data.startPrice} - USD ${data.endPrice}`
                      : `USD ${data.startPrice}+`
                    : `Less than ${data} months`
                }
              />
            ))}
          </Form>
          <Form.Check
            className="filter-radio"
            type="radio"
            label={`All ${type === "priceFilterData" ? "Price" : "Time"}`}
            checked={isFilterReset}
            onChange={resetFilter}
          />
        </div>
      )}
    </div>
  );
};

const BottomFilter = (props: {
  type: string;
  data: any[];
  filter: string[];
  setFilter: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { type, data, filter, setFilter } = props;

  const [isBottomFilterOpen, setIsBottomFilterOpen] = useState(false);

  const resetFilter = () => {
    setFilter([]);
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (e.target.checked) {
      setFilter((prevFilter) => [...prevFilter, value]);
    } else {
      setFilter((prevFilter) => prevFilter.filter((item) => item !== value));
    }
  };

  return (
    <div>
          <Button
            variant="link"
            style={{ color: "inherit", textDecoration: 'none', width: '100%' }}
            onClick={() => setIsBottomFilterOpen(!isBottomFilterOpen)}
          >
      <div className="h3-heading">
        <div>
          <FontAwesomeIcon
            icon={type === "Category" ? faRectangleList : faUser}
            style={{ marginRight: 12 }}
          />
          Sort by {type} <span></span>
        </div>
          <FontAwesomeIcon
            icon={isBottomFilterOpen ? faChevronUp : faChevronDown}
          />
      </div>
        </Button>
      {isBottomFilterOpen && (
        <div className="checkbox-container">
          {data.map((data, index) => (
            <Form key={Math.random()}>
              <Form.Check
                type="checkbox"
                className="filter-checkbox"
                id={`${type}-${index}`}
                name={type}
                value={type === "Category" ? data.category : data.publisher}
                checked={filter.includes(
                  type === "Category" ? data.category : data.publisher
                )}
                onChange={handleCheck}
                label={type === "Category" ? data.category : data.publisher}
              />
            </Form>
          ))}
          <div className="show-all-btn">
            <button onClick={resetFilter}>Show All</button>
          </div>
        </div>
      )}
    </div>
  );
};

const Filter = (props: FilterProps) => {
  const {
    apiType,
    priceFilter,
    setPriceFilter,
    publishedDateFilter,
    setPublishedDateFilter,
    categoryFilter,
    setCategoryFilter,
    publisherFilter,
    setPublisherFilter,
  } = props;
  const { categories, isLoading: isCategoriesLoading } =
    useContext(CategoryContext);
  const { publishers, isLoading: isPublishersLoading } =
    useContext(PublisherContext);

  const isMobile = useIsMobile();

  const [filterOpen, setFilterOpen] = useState(!isMobile);

  const resetAllFilters = () => {
    setPriceFilter({ startPrice: 0, endPrice: Infinity });
    setPublishedDateFilter("");
    setCategoryFilter([]);
    setPublisherFilter([]);
  };

  const handleFilterClick = () => {
    if (isMobile) setFilterOpen(!filterOpen);
  };

  return (
    <div className="filter-root">
      <Loader isLoading={isCategoriesLoading || isPublishersLoading} />
      <h4 className="report-filter-heading" onClick={handleFilterClick}>
        <div className="d-flex gap-2 align-items-center ">
          <FontAwesomeIcon icon={faFilter} color="#fff" />
          Filters
        </div>
        <button onClick={resetAllFilters}>Reset Filter</button>
      </h4>
      {filterOpen && (
        <>
          <TopFilter
            filterData={priceFilterData}
            type="priceFilterData"
            selectedFilter={priceFilter}
            setSelectedFilter={setPriceFilter}
          />
          <TopFilter
            filterData={publishedDateFilterData}
            type="publishedDateFilterData"
            selectedFilter={publishedDateFilter}
            setSelectedFilter={setPublishedDateFilter}
          />
          {apiType !== "category" && (
            <BottomFilter
              type="Category"
              data={categories}
              filter={categoryFilter}
              setFilter={setCategoryFilter}
            />
          )}
          {apiType !== "publisher" && (
            <BottomFilter
              type="Publisher"
              data={publishers}
              filter={publisherFilter}
              setFilter={setPublisherFilter}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Filter;
