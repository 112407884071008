import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import ReactQuill from "react-quill";
import { BlogContext } from "../../../contexts/BlogContext";
import "react-quill/dist/quill.snow.css";

interface AdminBlogsHeaderProps {
  modules: any;
  formats: string[];
}

const AdminBlogsHeader = (props: AdminBlogsHeaderProps) => {
  const { modules, formats } = props;
  const { postBlog } = useContext(BlogContext);
  const [showAddBlogsModal, setShowAddBlogsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const handleBlogAdd = async () => {
    try {
      setIsLoading(true);
      await postBlog({ title, content, metaDescription });
      setTitle("");
      setContent("");
      setMetaDescription("");
      handleClose();
      toast.success("Blog added successfully!");
    } catch (error) {
      toast.error("An error occurred while adding the blog.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBlogDiscard = () => {
    setTitle("");
    setContent("");
    setMetaDescription("");
  };

  const handleClose = () => {
    setShowAddBlogsModal(false);
  };

  return (
    <div>
      <Loader isLoading={isLoading} />
      <h2>
        Blog<span></span>
      </h2>
      <div className="add-blog-btn-container">
        <Button
          onClick={() => setShowAddBlogsModal(true)}
          className="add-blog-btn"
          variant="dark"
        >
          <FontAwesomeIcon icon={faPlus} color="#fff" /> Add Blog
        </Button>
      </div>

      <Modal show={showAddBlogsModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="add-blog-modal-header">
          <Modal.Title>Add Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="form-label">Title</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="text-field"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Content</Form.Label>
              <ReactQuill
                value={content}
                onChange={setContent}
                modules={modules}
                formats={formats}
                className="text-field"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Meta Description</Form.Label>
              <Form.Control
                type="text"
                value={metaDescription}
                onChange={(e) => setMetaDescription(e.target.value)}
              />
            </Form.Group>
            <div className="buttons">
              <Button variant="primary" onClick={handleBlogAdd}>
                Add
              </Button>
              <Button variant="danger" onClick={handleBlogDiscard}>
                Discard
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminBlogsHeader;
